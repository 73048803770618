import Vue from 'vue'
import VueSVGIcon from 'vue-svgicon'

import App from './App.vue'
import router from './router'
import '@/filters'
import '@/plugins'
import './registerServiceWorker'
import crossbarService from './services/crossbar'
import { vrsStore } from './store/'
import preferences from './services/preferences'

Vue.config.productionTip = false
Vue.use(VueSVGIcon)

new Vue({
  name: 'MFS Weather App',
  router,
  render: h => h(App)
}).$mount('#app')

/**
 * Boot instructions
 * * if token is defined, refresh it to check validity
 * * fetch the config if it doesn't exist
 * * fetch all the events from the server
 * * use the last route available in the local storage
 * * if nothing is available (eg: first boot),
 *   * use the active event
 *   * display the default page
 * * start crossbar
 */

// if user is authenticated (how to know it ?)

async function boot () {
  if (preferences.token) {
    await vrsStore.modules.auth.actions.refreshToken()
  }
  if (!CONFIGURATION) { // defined in globals.d.ts
    await vrsStore.modules.config.actions.fetchConfig()
  }
  await vrsStore.modules.auth.actions.getUser()
  if (vrsStore.modules.auth.state.data.isAuthenticated) {
    await vrsStore.modules.event.actions.fetchAllEvents()
  }
  crossbarService.open()
}

boot()
