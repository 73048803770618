
































































































































































import Vue from 'vue'

import ForecastLegend from './ForecastLegend.vue'
import ForecastOneHour from './ForecastOneHour.vue'
import TemperatureLine from '../../common/TemperatureLine/TemperatureLine.vue'
import MixGraph from '@/components/data/common/MixGraph/MixGraph.vue'

import DraggableComponent from '@/components/ui/DraggableComponent/DraggableComponent.vue'
import UpdateTime from '@/components/ui/UpdateTime/UpdateTime.vue'

import stylesVars from '@/styles/colors'

export default Vue.extend({
  name: 'forecast-hourly',
  components: {
    'mix-graph': MixGraph,
    'draggable-component': DraggableComponent,
    'forecast-hourly-legend': ForecastLegend,
    'forecast-hourly-one-hour': ForecastOneHour,
    'temperature-line': TemperatureLine,
    'ui-update-time': UpdateTime
  },
  props: {
    displaySession: {
      type: Boolean,
      default: false
    },
    displayPreviousNight: {
      type: Boolean,
      default: true
    },
    displayHumidity: {
      type: Boolean,
      default: false
    },
    displayPressure: {
      type: Boolean,
      default: true
    },
    displayGust: {
      type: Boolean,
      default: true
    },
    displayTrackMinMax: {
      type: Boolean,
      default: false
    },
    displayChanceOfRain: {
      type: Boolean,
      default: false
    },
    displayHourlyInfo: {
      type: Boolean,
      default: true
    },
    data: {
      // type: Array
    },
    displayDay: {
      type: Boolean,
      default: true
    },
    flattenData: {
      // type: Array,
      default: () => ([])
    },
    flattenDataIndex: {
      type: Number,
      default: 0
    },
    fontBase: Number,
    loading: Boolean,
    isMobile: Boolean,
    isDesktop: Boolean,
    isLegendVisible: {
      type: Boolean,
      default: true
    },
    sliderItemWidth: Number,
    temperatureOverLimit: {
      type: Number,
      required: false
    },
    chanceOfRainOverLimit: {
      type: Array,
      required: false
    },
    windOverLimit: {
      type: Array,
      required: false
    },
    gustsOverLimit: {
      type: Array,
      required: false
    },
    humidityOverLimit: {
      type: Number,
      required: false
    },
    pressureOverLimit: {
      type: Number,
      required: false
    },
    trackMinOverLimit: {
      type: Number,
      required: false
    },
    trackMaxOverLimit: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    mouseState: 'up',
    initialPositionX: null,
    initialPositionY: null,
    isPreviousNightCollapse: false,
    isWeatherConditionsCollapse: false,
    stylesVars,
    hideLegend: false
  }),
  mounted () {
    this.hideLegend = !!this.isMobile
    this.isPreviousNightCollapse = !!this.isMobile
    this.isWeatherConditionsCollapse = !!this.isMobile
  },
  computed: {
    temperaturesSet () {
      return this.flattenData.map(data => data.temperature)
    },
    tTrackMinSet () {
      return this.flattenData.map(data => data.trackMin)
    },
    tTrackMaxSet () {
      return this.flattenData.map(data => data.trackMax)
    },
    tAirTrackMin () {
      return Math.min(
        ...[
          ...this.tTrackMinSet,
          ...this.tTrackMaxSet,
          ...this.temperaturesSet
        ]
      )
    },
    tAirTrackMax () {
      return Math.max(
        ...[
          ...this.tTrackMinSet,
          ...this.tTrackMaxSet,
          ...this.temperaturesSet
        ]
      )
    },
    temperatureBottom () {
      return 'calc(' +
        (this.isMobile ? stylesVars.OffsetBottomTemperatureLine__mobile : stylesVars.OffsetBottomTemperatureLine__default) +
        (this.displayHumidity ? '' : ' - ' + stylesVars.OneHourSize__humidity) +
        (this.displayPressure ? '' : ' - ' + stylesVars.OneHourSize__pressure) +
        (this.displayGust ? '' : ' - ' + stylesVars.OneHourSize__wind + ' / 2') +
      ' - 3rem' + // used for better display with mix. temperature lower
      ')'
    },
    temperatureBottomMix () {
      return 'calc(' +
        (this.isMobile ? stylesVars.OffsetBottomTemperatureLine__mobile : stylesVars.OffsetBottomTemperatureLine__default) +
        (this.displayHumidity ? '' : ' - ' + stylesVars.OneHourSize__humidity) +
        (this.displayPressure ? '' : ' - ' + stylesVars.OneHourSize__pressure) +
        (this.displayGust ? '' : ' - ' + stylesVars.OneHourSize__wind + ' / 2') +
      ' - 0.5rem' + // used for better display with air t°. mix upper
      ')'
    },
    computeLinearGradientForAverage () {
      // Save number of dataHour for dataDay
      const elemByDay = this.data.map(dataPerDay => dataPerDay.data.length)

      // this.data === data per day
      const allData = this.data.reduce((acc: string[], dataPerDay) => {
        const data = dataPerDay.data.map(dataPerHour => dataPerHour.average)
        acc.push(...data)
        return acc
      }, [])

      const linearGradient: { background: string; color: string }[] = []
      allData.forEach((average: string, index: number) => {
        linearGradient.push({
          background: `linear-gradient(
            to right,
            ${this.getColor(average)} 70%,
            ${this.getColor(allData[index + 1] ?? average)}`,
          color: 'var(--color-font)'
        })
      })

      // Reformat data to match this.data
      return this.chunkize(linearGradient, elemByDay)
    },
    computeLinearGradientForGust () {
      // Save number of dataHour for dataDay
      const elemByDay = this.data.map(dataPerDay => dataPerDay.data.length)

      // this.data === data per day
      const allData = this.data.reduce((acc: string[], dataPerDay) => {
        const data = dataPerDay.data.map(dataPerHour => dataPerHour.gusts)
        acc.push(...data)
        return acc
      }, [])

      const linearGradient: { background: string; color: string }[] = []
      allData.forEach((gust: string, index: number) => {
        linearGradient.push({
          background: `linear-gradient(
            to right,
            ${this.getColor(gust)} 70%,
            ${this.getColor(allData[index + 1] ?? gust)}`,
          color: 'var(--color-font)'
        })
      })

      // Reformat data to match this.data
      return this.chunkize(linearGradient, elemByDay)
    },
    graphHeight () {
      return this.displayTrackMinMax ? 180 : 80
    }
  },
  methods: {
    toggleLegend () {
      this.hideLegend = !this.hideLegend
    },
    togglePreviousNight () {
      this.isPreviousNightCollapse = !this.isPreviousNightCollapse
    },
    toggleWeatherConditions () {
      this.isWeatherConditionsCollapse = !this.isWeatherConditionsCollapse
    },
    displayTemperatureLine (indexDay, indexHour) {
      return indexDay === 0 &&
              indexHour === 0 &&
              this.flattenData.length > 0 &&
              this.temperaturesSet &&
              this.temperaturesSet.length > 0 &&
              this.sliderItemWidth
    },
    /**
     * Split array into chunk
     * @param arrayToSplice
     * @param arrayChunkSize
     */
    chunkize (arrayToSplice: any[], arrayChunkSize: number[]) {
      const chunks = []
      let sommeElem = 0
      for (let i = 0; i < arrayChunkSize.length; i += 1) {
        chunks.push(arrayToSplice.slice(sommeElem, sommeElem + arrayChunkSize[i]))
        sommeElem += arrayChunkSize[i] // Sum elem to get correct index for next chunk
      }
      return chunks
    },
    getColor (value: string) {
      const firstLevel = 'rgba(45, 85, 154, 0.6)' // #2d559a - 0 to 10
      const secondLevel = 'rgba(91, 233, 99, 0.6)' // #5be963 - 5 to 15
      const thirdLevel = 'rgba(255, 188, 0, 0.6)' // #ffbc00 - 10 to 20
      const fourthLevel = 'rgba(255, 24, 0, 0.6)' // #ff1800 - 15 to 25
      const fifthLevel = 'rgba(209, 18, 0, 0.6)' // #d11200 - others

      if (value === '-') return 'transparent'
      if (value === '0 to 10') {
        return firstLevel
      }
      if (value === '5 to 15') {
        return secondLevel
      }
      if (value === '10 to 20') {
        return thirdLevel
      }
      if (value === '15 to 25') {
        return fourthLevel
      }
      return fifthLevel
    }
  }
})

