





















import Vue from 'vue'

/**
 * LiveButon toggles between live / archive mode
 */
export default Vue.extend({
  props: {
    live: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
})

