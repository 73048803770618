















import Vue from 'vue'
import Button from '@/components/ui/Button/Button.vue'

export default Vue.extend({
  components: {
    'ui-button': Button
  },
  data: () => ({
    isDisplayed: true
  }),
  methods: {
    reload () {
      document.location.reload()
    }
  }
})
