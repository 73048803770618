











































import { Component, Prop, Vue } from 'vue-property-decorator'
import { WeatherSeason, WeatherEvent } from '@/store/event/definitions'
import Button from '@/components/ui/Button/Button.vue'

@Component({
  components: {
    'ui-button': Button
  }
})
export default class ArchiveSelect extends Vue {
  @Prop({})
  seasons: WeatherSeason[]

  @Prop()
  events: WeatherEvent[]

  selectedSeason: WeatherSeason = null
  selectedEventId: WeatherEvent = null

  emitArchiveSelected () {
    this.$emit('archive-selected', this.selectedEventId)
  }
}

