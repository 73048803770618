



































import Vue from 'vue'

export default Vue.extend({
  props: {
    offset: Number,
    isDesktop: Boolean,
    sliderItemWidth: Number
  },
  data: () => ({
    mouseState: 'up',
    initialPositionX: null,
    initialPositionY: null,
    widthRef: null,
    widthChild: null
  }),
  computed: {
    isDraggableActive () {
      if (this.widthRef) {
        return this.widthChild > this.widthRef
      }
      return false
    }
  },
  methods: {
    handleMouseDown (e) {
      e.preventDefault()
      this.mouseState = 'down'
      this.initialPositionX = e.screenX
      this.initialPositionY = e.screenY
      this.initialScrollLeft = this.$refs.container.scrollLeft
    },
    handleMouseMove (e) {
      if (this.mouseState === 'down') {
        this.$refs.container.scrollLeft = this.initialPositionX - e.screenX + this.initialScrollLeft
      }
    },
    handleMouseUp (e) {
      e.preventDefault()
      this.mouseState = 'up'
      this.initialPositionX = null
      this.initialPositionY = null
    },
    backwardForecast () {
      if (this.$refs.container.scrollLeft > 0) {
        this.$refs.container.scrollLeft = this.$refs.container.scrollLeft - this.sliderItemWidth
      }
    },
    forwardForecast () {
      this.$refs.container.scrollLeft = this.$refs.container.scrollLeft + this.sliderItemWidth
    },
    handleResize () {
      this.widthChild = Array.from(this.$el.querySelectorAll('.draggable-width')).reduce((
        acc: number,
        elem: Element
      ) => {
        return acc + elem.clientWidth
      }, 0)
      if (this.$refs.container) {
        this.widthRef = this.$refs.container.clientWidth
      }
    }
  },
  async mounted () {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
})

