/**
 * Timeline mode
 * Range : allow a period range selection (useful for radar / obs°)
 * Single : allow a single date selection (useful for forecast)
 */
export enum Mode {
  Range = 'Range',
  Single = 'Single'
}

/**
 * Timeline events
 * * onRangeChange is triggered when the range is updated
 * * onTimeChange is triggered when the range is updated
 * * onClickOutsidePast is triggered when the user click in the left period outside
 * * onClickOutsideFuture is triggered when the user click in the right period outside
 * * onZoomChange is triggered when the user change the zoom
 * * onClickPOI is triggered when the user click on a period of interest
 */
export enum TimelineEvent {
  onRangeChange,
  onTimeChange,
  onClickOutsidePast,
  onClickOutsideFuture,
  onZoomChange,
  onClickPOI
}

/**
 * Period (Point) of interest
 */
export interface PeriodOfInterest {
  start: string;
  end: string;
  label: string;
}

/**
 * Number of days displayed in zoom max
 */
export const MAXZOOM_NB_DAYS = 3
