












































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

import { convertDate, getTimeFromString } from '@/helpers/dates'
import LiveButton from './LiveButton.vue'

@Component({
  components: {
    LiveButton
  }
})
export default class DateSelector extends Vue {
  // Is event active
  // If true, we can set live mode
  // If false, we are on an archived event
  @Prop({ default: true })
  public active: boolean;

  // useful for Nowcasting + Forecast, reference date for the moment
  @Prop({ default: null, required: true })
  public referenceDate: string

  @Prop({ default: true })
  public live: boolean

  // Array of two string elements, [ dateFrom, dateEnd ]
  // in yyyyMMddHHmmss format
  @Prop({ default: null })
  public dateRange: Array<string>

  /**
   * Array of dates in { yyyy/MM/dd, yyyyMMdd } format
   * for date selection
   */
  get dates (): Array<{value: string; datestring: string}> {
    if (!this.dateRange || this.dateRange?.length !== 2) return []

    const dateStart = convertDate(this.dateRange[0].toString())
    const dateEnd = convertDate(this.dateRange[1].toString())

    const dates = []
    for (
      let date = dateStart;
      date < dateEnd;
      date = date.plus({ days: 1 })
    ) {
      // We need value to display in select menu
      // and datestring to pass on handle function (and send to TimesProvider)
      dates.push({
        value: date.toFormat('yyyy-MM-dd'),
        datestring: date.toFormat('yyyyMMdd')
      })
    }
    return dates
  }

  getDate (timeString: string) {
    return timeString ? timeString.substring(0, 8) : ''
  }

  getTimeFromString = getTimeFromString

  changeCurrentTime (e, type) {
    let dateString
    let timeString

    switch (type) {
      case 'date':
        dateString = e.target.value
        timeString = this.referenceDate.substring(8, 12)
        break
      case 'time':
        dateString = this.referenceDate.substring(0, 8)
        timeString = e.target.value.replace(':', '')
        break
    }
    // Update referenceDate for forecast
    const newDateString = `${dateString}${timeString}00`
    this.$emit('change-ref-date', newDateString)
  }

  toggleLive () {
    this.$emit('toggle-live')
  }

  onClose () {
    this.$emit('close')
  }
}

