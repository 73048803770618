






import Vue from 'vue'
export default Vue.extend({
  name: 'update-time',
  props: {
    updateTime: String
  }
})
