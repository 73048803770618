import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home/Home.vue'
import MediaDashboard from '../views/MediaDashboard/MediaDashboard.vue'
import NotFound from '../views/NotFound/NotFound.vue'
import Profile from '../views/Profile/Profile.vue'

import { vrsStoreAuth } from '../store/auth/store'
import { vrsStoreApp } from '../store/app/store'
import preferences from '../services/preferences'
import { AppRoute } from '../store/app/definitions'

Vue.use(Router)

const routes = [{
  path: '/',
  name: AppRoute.HOME,
  component: Home,
  meta: {
    withHeader: false,
    requireAuth: false
  }
},
{
  path: '/login',
  name: AppRoute.LOGIN,
  component: Home,
  meta: {
    withHeader: false,
    requireAuth: false
  }
},
{
  path: '/signup',
  name: AppRoute.SIGNUP,
  component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp/SignUp.vue'),
  meta: {
    withHeader: false,
    requireAuth: false
  }
},
{
  path: '/privacy-policy',
  name: AppRoute.PRIVACY_POLICY,
  component: () => import(/* webpackChunkName: "signup" */ '../views/PrivacyPolicy/PrivacyPolicy.vue'),
  meta: {
    withHeader: false,
    requireAuth: false
  }
},
{
  path: '/lost-password',
  name: AppRoute.LOST_PASSWORD,
  component: () => import(/* webpackChunkName: "lost_password" */ '../views/LostPassword/LostPassword.vue'),
  meta: {
    withHeader: false,
    requireAuth: false
  }
},
{
  path: '/reset-password/',
  name: AppRoute.RESET_PASSWORD,
  props: true,
  component: () => import(/* webpackChunkName: "lost_password" */ '../views/LostPassword/ResetPassword.vue'),
  meta: {
    withHeader: false,
    requireAuth: false
  }
},

{
  path: '/profile',
  name: AppRoute.PROFILE,
  component: Profile,
  meta: {
    withHeader: true,
    withTimeline: false,
    requireAuth: true
  }
},
{
  path: '/settings',
  name: AppRoute.SETTINGS,
  component: () => import(/* webpackChunkName: "message" */ '../views/Settings/Settings.vue'),
  meta: {
    withHeader: true,
    withTimeline: false,
    requireAuth: false
  }
},
/**
 * Media routes
 */
{
  path: '/dashboard',
  name: AppRoute.MEDIA_DASHBOARD,
  component: MediaDashboard,
  meta: {
    withHeader: true,
    withHeaderRain: false,
    withTimeline: false,
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().dashboard
  }
},
{
  path: '/dashboard/:section',
  name: AppRoute.MEDIA_DASHBOARD_SECTION,
  component: MediaDashboard,
  props: true,
  meta: {
    withHeader: true,
    withHeaderRain: false,
    withTimeline: false,
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().dashboard
  }
},
{
  path: '/messages',
  name: AppRoute.MEDIA_MESSAGES,
  component: () => import(/* webpackChunkName: "message" */ '../views/Messages/Messages.vue'),
  props: {
    eventId: 'active'
  },
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Single',
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().dashboard
  }
},

/**
 * Team routes
 */
{
  path: '/event/:eventId/climaterecap',
  name: AppRoute.CLIMATERECAP,
  component: () => import(/* webpackChunkName: "climateRecap" */ '../views/ClimateRecap/ClimateRecap.vue'),
  props: true,
  meta: {
    withHeader: true,
    withTimeline: false,
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().climateRecap
  }
},
{
  path: '/event/:eventId/forecast',
  name: AppRoute.FORECAST,
  component: () => import(/* webpackChunkName: "forecast" */ '../views/Forecast/Forecast.vue'),
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Single',
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().forecast
  }
},
{
  path: '/event/:eventId/nowcasting',
  name: AppRoute.NOWCASTING,
  component: () => import(/* webpackChunkName: "nowcasting" */ '../views/Nowcasting/Nowcasting.vue'),
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Range',
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().nowcasting
  },
  /**
   * Before entering this route, we set the default animation length,
   * This help us implement the customer rule for custumizing the default range displayed.
   */
  beforeEnter (to, from, next) {
    vrsStoreApp.state.data.currentPageDefaultAnimationLength = 30
    vrsStoreApp.state.data.animationLength = to.query.length || 30
    next()
  }
},
{
  path: '/event/:eventId/observations/:stationCode',
  name: AppRoute.OBSERVATIONS_DETAILS,
  component: () => import(/* webpackChunkName: "observations" */ '../views/Observations/Observations.vue'),
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Range',
    requireAuth: true,
    props: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().observations
  },
  /**
   * Before entering this route, we set the default animation length,
   * This help us implement the customer rule for custumizing the default range displayed.
   */
  beforeEnter (to, from, next) {
    vrsStoreApp.state.data.currentPageDefaultAnimationLength = 240
    vrsStoreApp.state.data.animationLength = to.query.length || 240
    next()
  }
},
{
  path: '/event/:eventId/observations',
  name: AppRoute.OBSERVATIONS,
  component: () => import(/* webpackChunkName: "observations" */ '../views/Observations/Observations.vue'),
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Range',
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().observations
  },
  /**
   * Before entering this route, we set the default animation length,
   * This help us implement the customer rule for custumizing the default range displayed.
   */
  beforeEnter (to, from, next) {
    vrsStoreApp.state.data.currentPageDefaultAnimationLength = 240
    vrsStoreApp.state.data.animationLength = to.query.length || 240
    next()
  }
},
{
  path: '/event/:eventId/message',
  name: AppRoute.MESSAGES,
  component: () => import(/* webpackChunkName: "message" */ '../views/Messages/Messages.vue'),
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Single',
    requireAuth: true,
    requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().messages
  }
},
{
  path: '/event/:eventId/dashboard/:dashboardIndex',
  name: AppRoute.CUSTOM_DASHBOARD,
  props: true,
  component: () => import(/* webpackChunkName: "message" */ '../views/CustomDashboard/CustomDashboard.vue'),
  meta: {
    withHeader: true,
    withTimeline: true,
    timelineMode: 'Range',
    requireAuth: true
    // requirePermission: () => vrsStoreAuth.computed.displayMenuOptions().messages
  },
  /**
   * Before entering this route, we set the default animation length,
   * This help us implement the customer rule for custumizing the default range displayed.
   */
  beforeEnter (to, from, next) {
    vrsStoreApp.state.data.currentPageDefaultAnimationLength = 240
    vrsStoreApp.state.data.animationLength = to.query.length || 240
    next()
  }
},
{
  path: '/map',
  component: () => import('../views/EventChoice/EventChoice.vue'),
  meta: {
    withHeader: true,
    withTimeline: false,
    requireAuth: true
  }
},
/**
 * Last one, for 404
 */
{
  path: '*',
  name: AppRoute.NOTFOUND,
  component: NotFound,
  meta: {
    withHeader: false,
    requireAuth: false
  }
}
]

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

/**
 * Manage query string by affecting data to stores
 */
router.beforeEach(async function checkAuthentication (to, from, next) {
  // console.log('[router] beforeEach')
  const needPermission = to.meta.requirePermission !== undefined
  const isPermissionMatched = needPermission ? to.meta.requirePermission() : false
  const needAuthent = to.meta.requireAuth
  const isAuthenticated = vrsStoreAuth.state.data.isAuthenticated

  const isLastURLCorrect = preferences.lastURL &&
    preferences.lastURL !== '' &&
    preferences.lastURL !== '/' &&
    preferences.lastURL.indexOf('/login') === -1

  if (!needAuthent && !isAuthenticated) {
    // we let the navigation pass if user is not authenticated
    // and wish to go on an unauth route
    next()
  } else if (!needAuthent && isAuthenticated) {
    // we block navigation if user is authenticated
    // and wish to go on an unauth route
    next({
      path: vrsStoreAuth.computed.defaultHomePage()
    })
  } else if (needAuthent && !isAuthenticated) {
    // if we want to go to an auth route, but we are not, go to login
    next({
      path: '/login',
      query: {
        redirect: isLastURLCorrect ? preferences.lastURL : ''
      }
    })
  } else if (
    (needAuthent && isAuthenticated && !needPermission) ||
    (needAuthent && isAuthenticated && needPermission && isPermissionMatched)
  ) {
    // console.log('[router] going to the authenticated route')
    const newEventId = to.params.eventId || vrsStoreApp.state.data.queryString.eventId as string || 'active'
    // const isNewEvent = vrsStoreApp.state.data.queryString.eventId !== newEventId
    const newReferenceDate = (to.query.referenceDate && to.query.referenceDate.toString()) // || (!isNewEvent ? vrsStoreApp.state.data.referenceDate : null)
    // feed the vrsStoreApp queryString with URL params
    // console.log('[router] updating queryString from ', JSON.parse(JSON.stringify(vrsStoreApp.state.data.queryString)))
    vrsStoreApp.state.data.queryString = {
      eventId: newEventId,
      referenceDate: newReferenceDate,
      length: (to.query.length && parseInt(to.query.length.toString())) || vrsStoreApp.state.data.queryString.length,
      zoomTimeline: (to.query.zoomTimeline && parseFloat(to.query.zoomTimeline.toString())) || vrsStoreApp.state.data.queryString.zoomTimeline,
      route: to.name as AppRoute
    }
    // console.log('[router] to ', JSON.parse(JSON.stringify(vrsStoreApp.state.data.queryString)))
    if (to.fullPath !== '/') preferences.lastURL = to.fullPath
    next()
  } else if (needAuthent && isAuthenticated && needPermission && !isPermissionMatched) {
    next({
      name: AppRoute.NOTFOUND
    })
  } else {
    preferences.lastURL = to.fullPath
    next()
  }
})

export default router
