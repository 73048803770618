


































































import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import RainInformation from './RainInformation.vue'
import { convertDate } from '@/helpers/dates'

export default Vue.extend({
  name: 'rain-alert',
  components: {
    'ui-loader': Loader,
    'forecast-rain-information': RainInformation
  },
  props: {
    defaultAlertInfo: String,
    loading: Boolean,
    referenceDate: {
      type: Object
    },
    rain: {
      type: Object,
      default: () => ({})
    },
    isLive: Boolean,
    maximumTimeLive: String,
    referenceDateString: String
  },
  data () {
    return {
      isOpen: false
    }
  },
  computed: {
    bgClass () {
      let cssClass = ''
      if (!this.rain) return cssClass
      switch (this.rain?.status) {
        case 0:
          cssClass += 'bg-green '
          break
        case 1:
          cssClass += 'bg-orange '
          break
        case 2:
          cssClass += 'bg-red '
          break
        case 3:
          cssClass += 'bg-yellow '
          break
      }
      if ([0, 1, 2, 3].indexOf(this.rain?.status) >= 0) {
        cssClass += 'text-primary-dark '
      } else {
        cssClass += 'bg-background '
      }
      return cssClass
    },
    timeExpectedRain (): string|null {
      return this.timeRemaining(this.rain.beginning) || null
    },
    timeStopRain (): string|null {
      return this.timeRemaining(this.rain.end) || null
    }
  },
  methods: {
    toggleDetails () {
      this.isOpen = !this.isOpen
    },
    timeRemaining (time) {
      if (!time) return null
      /**
       * Sometimes, the time is a number, without the `:` character
       * We have to convert it in a HH:mm format
       *
       * Sometimes, it's a "after HHmm", other "> HH:mm"
       *
       * Well... need some regexp to extract what we need !
       */
      const re = /(\d+):*(\d\d)/

      const parseTime = re.exec(time)

      if (parseTime.length !== 3) return null

      /**
       * If the rain alert is in live mode, prefer using the maximumTimeLive string
       * if not, use the referenceDate
       */
      const date = this.isLive ? convertDate(this.maximumTimeLive) : this.referenceDate
      const from = date.set({
        hour: parseTime[1],
        minute: parseTime[2]
      })
      const diff = from.diff(date)
      if (diff.as('minutes') > 0) return `${diff.toFormat('m')}min`
    }
  }
})

