/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'station': {
    width: 400,
    height: 400,
    viewBox: '0 0 400 400',
    data: '<path pid="0" d="M66.695 39.88c-3.234 1.601-4.939 3.241-10.924 10.513-36.456 44.293-35.133 109.881 3.079 152.685 9.614 10.769 17.68 12.595 25.009 5.661 6.923-6.55 6.142-14.641-2.296-23.786-30.959-33.556-31.158-86.117-.453-119.59 13.07-14.249 2.092-33.654-14.415-25.483m254.298-.076c-7.673 4.175-10.349 14.872-5.268 21.059a898.233 898.233 0 006.15 7.333c20.605 24.346 26.141 59.841 14.063 90.162-4.049 10.164-8.6 17.052-20.356 30.808-9.568 11.195 2.322 27.712 16.203 22.51 7.405-2.775 23.661-24.633 30.239-40.658 17.299-42.141 9.811-89.573-19.455-123.23-8.092-9.307-14.67-11.741-21.576-7.984M118.359 68.306c-8.822 3.196-21.326 23.514-25.034 40.678-7.724 35.762 16.76 83.104 37.73 72.953 9.625-4.66 10.524-15.195 2.124-24.906-15.226-17.601-16.729-43.253-3.5-59.765 6.718-8.386 7.821-10.531 7.821-15.205 0-10.305-9.607-17.209-19.141-13.755m153.907.055c-10.809 3.822-12.558 15.386-3.856 25.5 15.505 18.022 15.13 45.213-.872 63.146-10.807 12.112-5.97 26.596 8.88 26.584 10.143-.008 24.375-18.555 29.972-39.06 9.391-34.399-12.714-83.74-34.124-76.17M189.453 90.16c-16.966 5.388-26.562 18.561-26.562 36.461v9.825l3.285 6.631c2.339 4.72 4.552 7.828 7.677 10.783l4.39 4.153-31.711 87.204c-33.4 91.848-33.317 91.587-30.586 96.814 3.928 7.519 13.233 10.247 20.475 6.003 4.17-2.444 5.739-5.649 13.667-27.921l5.771-16.215 44.023.2 44.023.199 7.149 19.668c3.932 10.818 7.852 20.526 8.712 21.575 6.18 7.539 21.045 4.888 24.557-4.38 1.833-4.837.857-7.839-31.047-95.548l-31.862-87.591 4.399-3.949c28.805-25.867.501-75.619-36.36-63.912m19.003 116.871l7.814 21.485-16.061.211c-8.834.116-16.206.067-16.383-.109-.176-.177 3.279-10.21 7.679-22.296 4.399-12.086 8.255-21.705 8.567-21.375.313.33 4.086 10.268 8.384 22.084m21.166 58.399a3226.688 3226.688 0 004.549 12.5c.293.772-6.903.976-34.388.976h-34.758l4.212-11.523c2.317-6.338 4.374-11.963 4.573-12.5.278-.754 6.2-.977 25.997-.977h25.637l4.178 11.524" fill-rule="evenodd"/>'
  }
})
