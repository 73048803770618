



























































































































































import ToggleSwitch from '@/components/ui/ToggleSwitch/ToggleSwitch.vue'
import Button from '@/components/ui/Button/Button.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  components: {
    'ui-toggle-switch': ToggleSwitch,
    'ui-button': Button,
    'vue-slider': VueSlider,
    'ui-dialog': Dialog
  },
  props: {
    /**
     * Dashboard label
     */
    label: {
      type: String,
      required: true
    },
    /**
     * Dashboard Settings
     */
    displayMapBackground: {
      type: Boolean
    },
    displayMapPlayer: {
      type: Boolean
    },
    mapPlayerPosition: {
      type: String
    },
    /**
     * Mode, creation / update
     */
    mode: {
      type: String,
      default: 'update'
    },

    /**
     * Map settings
     */
    osmOpacity: {
      type: Number,
      required: false
    },
    radarOpacity: {
      type: Number,
      required: false
    },
    displayLayersWeatherStations: {
      type: Boolean,
      required: false
    },
    displayLayersWindArrows: {
      type: Boolean,
      required: false
    },
    displayLayersRainpath: {
      type: Boolean,
      required: false
    },
    syncedWithPlayer: {
      type: Boolean,
      required: false
    }

  },
  computed: {
    dialogHeader () {
      return this.mode === 'creation' ? 'New dashboard settings' : 'Dashboard settings'
    }
  }
}
