import { render, staticRenderFns } from "./PopupReload.vue?vue&type=template&id=25cc9ce6&scoped=true&"
import script from "./PopupReload.vue?vue&type=script&lang=ts&"
export * from "./PopupReload.vue?vue&type=script&lang=ts&"
import style0 from "./PopupReload.vue?vue&type=style&index=0&id=25cc9ce6&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25cc9ce6",
  null
  
)

export default component.exports