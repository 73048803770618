import { StateWeatherStationAverageTemperature } from './definitions'
import apiService from '@/services/api'
import { DateTime } from 'luxon'
import { createStore } from 'vue-reactive-store'
import { getNowDateTime, stringifyDate } from '@/helpers/dates'
import saveAs from 'file-saver'
import { vrsStoreEvent } from '../event/store'

const state: StateWeatherStationAverageTemperature = {
  loading: false,
  loadingLatest: false,
  loadingExport: false,
  error: null,
  data: null,
  apiData: null,
  latest: null
}

/**
 * For each station, we browse each data and extract what we need
 * then we sort by time
 */
const wsWorker = new Worker('@/worker/mfs.worker.ts', { type: 'module' })

const store = {
  name: 'averageTemperature',
  state,
  actions: {
    /**
     * Get the latest average temperature
     *
     * @param eventId Id of the event we want to retrieve the latest avgtemp
     */
    async fetchWeatherStationAverageTemperatureDataLatest (eventId: number) {
      store.state.loadingLatest = true
      try {
        const response = await apiService.getAverageTemperatureLatest(eventId)
        state.latest = {
          average: response.data?.data?.average,
          averageLuminosity: response.data?.data?.averageLuminosity,
          value_date: response.data.value_date,
          create_datetime: response.data.create_datetime
        }
      } catch (e) {
        console.error(e)
        store.state.error = e
      }
      store.state.loadingLatest = false
    },

    /**
     * Get the average temperature for an event, during a period defined by from / to dates
     *
     * @param eventId Id of the event we want to retrieve the latest avgtemp
     * @param fromDateLuxon Period's from
     * @param toDateLuxon Period's to
     */
    async fetchWeatherStationAverageTemperatureDataFilteredByDate (
      eventId: number,
      fromDateLuxon: DateTime,
      toDateLuxon: DateTime
    ) {
      store.state.loading = true
      try {
        const response = await apiService.getAverageTemperatureFilteredByDate(
          eventId,
          fromDateLuxon,
          toDateLuxon
        )

        wsWorker.postMessage({
          type: 'weatherstationavgtemp',
          data: response.data
        })
      } catch (e) {
        console.error(e)
        store.state.error = e
        store.state.loading = false
      }
    },

    /**
     * Filter data by date
     *
     * @param {DateTime} date DateTime object of avgtemp data to show
     * @param {string} timezone
     */
    computeAvgTempDataToDisplay (currentTime: string) {
      // If no apiData, do nothing
      if (!state.apiData) return

      state.data = store.state.apiData[currentTime]
    },

    async exportCSV (eventId: number, fromDateLuxon: DateTime, toDateLuxon: DateTime) {
      store.state.loadingExport = true
      const csvData = []
      const response = await apiService.getAverageTemperatureFilteredByDate(
        eventId,
        fromDateLuxon,
        toDateLuxon
      )
      let maximumNumberOfStations = 0
      response.data.forEach(currentData => {
        if (currentData.data.stationsdata.length > maximumNumberOfStations) {
          maximumNumberOfStations = currentData.data.stationsdata.length
        }
        csvData.push(
          currentData.id + ',' +
          currentData.data.average + ',' +
          currentData.value_date + ',' +
          currentData.create_datetime + ',' +
          currentData.data.stationsdata.map(sd => (
            sd.station_id + ',' +
            sd.station_ref + ',' +
            sd.temperature + ',' +
            sd.value_date + ',' +
            sd.create_datetime
          )).join(',') + '\n'
        )
      })
      let csvHeader = 'id,average,value_date,create_datetime'
      for (let i = 0; i < maximumNumberOfStations; i++) {
        csvHeader += ',station_id,station_ref,station_temperature,station_value_date,station_create_datetime'
      }
      csvHeader += '\n'
      csvData.splice(0, 0, csvHeader)
      const blob = new Blob(csvData, { type: 'text/plain;charset=utf-8' })
      const now = stringifyDate(getNowDateTime())
      saveAs(blob, now + '_averagetemp_' + vrsStoreEvent.state.data.code + '.csv')
      store.state.loadingExport = false
    },

    resetState () {
      state.data = null
      state.apiData = null
      state.latest = null
    }

  }
}

wsWorker.onmessage = function (e) {
  // console.log('[avgtemp store] worker result', e, stringifyDate(toDateLuxon))
  store.state.loading = false
  store.state.apiData = e.data.avgtempTimestamp
  const latestKnownDate = Object.keys(e.data.avgtempTimestamp).reduce((acc, currentKey) => currentKey > acc ? currentKey : acc, null)

  /**
   * If no avgtemp timestamp is available,
   * we can't set the data
   */
  if (!latestKnownDate) return
  store.state.data = store.state.apiData[latestKnownDate]
  // console.log('[Store AVG Temp] AVG worker is going to be terminated...')
  // wsWorker.terminate()
}

export const fetchWeatherStationAverageTemperatureDataLatest = store.actions.fetchWeatherStationAverageTemperatureDataLatest

export const fetchWeatherStationAverageTemperatureDataFilteredByDate = store.actions.fetchWeatherStationAverageTemperatureDataFilteredByDate

export const computeAvgTempDataToDisplay = store.actions.computeAvgTempDataToDisplay

export const vrsStoreWeatherStationAverageTemperature = createStore(store)
