<template>
  <div class="NotFound">
    <ui-loader message="loading config..." variant="font" v-if="config.loading" />
    <div v-else>
      <div class="NotFound__header" v-if="!config.loading">
        <h2>Page not found !</h2>
        <p>Ooops ?...</p>
        <p>Well, seems it's not the right road for your trip...</p>
        <p>Come back to the default page</p>
        <p>
          <router-link :to="defaultHomePage">
            by clicking here !
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/ui/Loader/Loader.vue'

import { vrsStoreConfig } from '@/store/config/store'
import { vrsStoreAuth } from '../../store/auth/store'

export default {
  name: 'notfound',
  data: () => ({
    config: vrsStoreConfig.state
  }),
  computed: {
    defaultHomePage: vrsStoreAuth.computed.defaultHomePage
  },
  components: {
    'ui-loader': Loader
  }
}
</script>

<style lang="postcss" scoped>
.NotFound {
  display: flex;
  height: 100%;
  padding: 2em $default-spacing;

  h2 {
    margin-bottom: 2rem;
  }

  .Loader {
    width: 100%;
  }
}

</style>
