





























































import Vue from 'vue'
export default Vue.extend({
  name: 'forecast-one-day',
  props: {
    day: String,
    isMessage: Boolean,
    displayPressure: Boolean,
    pressure: String,
    isAverageOverLimit: Boolean,
    isRainOverLimit: Boolean,
    isTemperatureMinOverLimit: Boolean,
    isTemperatureMaxOverLimit: Boolean,
    observation: Array,
    temperatureMin: Number,
    temperatureMax: Number,
    weatherMorning: Number,
    weatherAfternoon: Number,
    rain: String,
    wind: String,
    average: String,
    temperatureOverLimit: {
      type: Number,
      required: false
    },
    chanceOfRainOverLimit: {
      type: Array,
      required: false
    },
    pressureOverLimit: {
      type: Number,
      required: false
    }
  },
  computed: {
    isChanceOfRainOverLimit () {
      // To keep previous behavior
      if (this.isRainOverLimit) return this.isRainOverLimit
      return this.rain && this.chanceOfRainOverLimit ? this.chanceOfRainOverLimit.indexOf(this.rain) >= 0 : false
    },
    isPressureOverLimit () {
      // To keep previous behavior
      if (this.isAverageOverLimit) return this.isAverageOverLimit
      return this.pressure && this.pressureOverLimit ? this.pressure >= this.pressureOverLimit : false
    },
    isTempMinOverLimit () {
      // To keep previous behavior
      if (this.isTemperatureMinOverLimit) return this.isTemperatureMinOverLimit
      return this.temperatureMin && this.temperatureOverLimit ? this.temperatureMin >= this.temperatureOverLimit : false
    },
    isTempMaxOverLimit () {
      // To keep previous behavior
      if (this.isTemperatureMaxOverLimit) return this.isTemperatureMaxOverLimit
      return this.temperatureMax && this.temperatureOverLimit ? this.temperatureMax >= this.temperatureOverLimit : false
    }
  }
})

