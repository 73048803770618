export const defaultDashboard = {
  label: 'New dashboard',
  layout: [],
  settings: {
    displayMapBackground: false,
    displayMapPlayer: false,
    mapPlayerPosition: 'top',
    osmOpacity: 1,
    radarOpacity: 1,
    displayLayersWeatherStations: false,
    displayLayersWindArrows: false,
    displayLayersRainpath: false
  }
}
