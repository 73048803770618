import apiService from '@/services/api'
import { AirParifDataFromAPI, AirParifPollutant, AirParifPollutantKey, AirParifThresholdKey, StateAirParif, AirParifThreshold } from './definitions'
import { createStore } from 'vue-reactive-store'
import { vrsStoreApp } from '../app/store'
import { D3TimelineSerie } from '@/components/ui/Timeline/D3Timeline/timeline'
import { DateTime } from 'luxon'
import { convertDate, stringifyDate } from '@/helpers/dates'
import { vrsStoreEvent } from '../event/store'

/**
 * These settings are from AirParif code source
 * see https://www.airparif.asso.fr/
 * and check them with breakpoints :-)
 */
const airparifPollutants: Record<AirParifPollutantKey, AirParifPollutant> = {
  indice: {
    short: 'Global indice',
    api: 'indice',
    thresholds: [
      { key: 'low', value: 12, position: '0%' },
      { key: 'average', value: 28, position: '20%' },
      { key: 'degrade', value: 44, position: '40%' },
      { key: 'high', value: 60, position: '60%' },
      { key: 'very_high', value: 76, position: '80%' },
      { key: 'extreme', value: 90, position: '100%' }
    ]
  },
  o3: {
    short: 'O₃',
    api: 'o3',
    thresholds: [
      { key: 'low', value: 50.5 },
      { key: 'average', value: 100.5 },
      { key: 'degrade', value: 130.50 },
      { key: 'high', value: 240.5 },
      { key: 'very_high', value: 380.5 },
      { key: 'extreme', value: null }
    ]
  },
  no2: {
    short: 'NO₂',
    api: 'no2',
    thresholds: [
      { key: 'low', value: 40.5 },
      { key: 'average', value: 90.5 },
      { key: 'degrade', value: 120.50 },
      { key: 'high', value: 230.5 },
      { key: 'very_high', value: 340.5 },
      { key: 'extreme', value: null }
    ]
  },
  pm10: {
    short: 'PM₁₀',
    api: 'pm10',
    thresholds: [
      { key: 'low', value: 20.5 },
      { key: 'average', value: 40.5 },
      { key: 'degrade', value: 60.50 },
      { key: 'high', value: 90.5 },
      { key: 'very_high', value: 130.5 },
      { key: 'extreme', value: 140 }
    ]
  },
  pm25: {
    short: 'PM₂.₅',
    api: 'pm25',
    thresholds: [
      { key: 'low', value: 10.5 },
      { key: 'average', value: 20.5 },
      { key: 'degrade', value: 30.50 },
      { key: 'high', value: 60.5 },
      { key: 'very_high', value: 100.5 },
      { key: 'extreme', value: 110 }
    ]
  }
}

const label: Record<Exclude<AirParifThresholdKey, 'no-value'>, string> = {
  low: 'Good',
  average: 'Fair',
  degrade: 'Moderate',
  high: 'Poor',
  very_high: 'Very poor',
  extreme: 'Extremely poor'
}

function getPollutantThreshold (value, pollutantKey: AirParifPollutantKey): AirParifThreshold {
  if (!value) {
    return {
      key: 'no-value',
      value: Infinity
    }
  }
  if (value <= 0) {
    return {
      key: 'no-value',
      value: Infinity
    }
  }

  /**
   * Find the first threshold under the value,
   * if no threshold is found, return the last one, 'extreme'
   */
  const pollutant = airparifPollutants[pollutantKey]
  const currentThreshold = pollutant.thresholds.find(t => value < t.value)

  return currentThreshold || {
    key: 'extreme',
    value: Infinity
  }
}

function getPollutantsThreshold (data: AirParifDataFromAPI): Record<AirParifPollutantKey, AirParifThreshold> {
  return {
    indice: getPollutantThreshold(data.data.indice, 'indice'),
    o3: getPollutantThreshold(data.data.o3, 'o3'),
    pm10: getPollutantThreshold(data.data.pm10, 'pm10'),
    pm25: getPollutantThreshold(data.data.pm25, 'pm25'),
    no2: getPollutantThreshold(data.data.no2, 'no2')
  }
}

const state: StateAirParif = {
  loading: false,
  data: null,
  apiData: null,
  error: null
}
const store = {
  name: 'airparif',
  state,
  computed: {
    /**
     * Return the latest airparif data
     */
    latest () {
      if (!state.data) return null
      if (state.data.length === 0) return null
      const currentData = state.data[state.data.length - 1]
      return {
        ...currentData,
        valueDateDisplayed: stringifyDate(DateTime.fromISO(currentData.valueDate), 'HH:mm')
      }
    },
    /**
     * Return the current airparif data
     * according to the referenceDate
     */
    current () {
      if (!store.state.data) return null
      if (state.data.length === 0) return null
      const referenceDate = stringifyDate(convertDate(vrsStoreApp.state.data.referenceDate, vrsStoreEvent.state.data.timezone).toUTC(), "yyyy-MM-dd'T'T':00.000Z'")

      /**
       * Find the airparif data the nearest in time before referenceDate.
       * find the first data where value_date < referenceDate
       */
      return store.state.data.find(d => d.valueDate < referenceDate)
    },
    /**
     * Time ranges for all airparif data retrieved
     * Useful for timeline purpose
     */
    availableTimeRangesLocal (): D3TimelineSerie {
      if (!store.state.data) return null
      const dataClone = [...store.state.data]
      return {
        type: 'airparif',
        name: 'AirParif data',
        data: dataClone ? dataClone
          .sort((a, b) => a.valueDate < b.valueDate ? -1 : 1)
          .map(d => {
            const currentValueDate = DateTime.fromISO(d.valueDate)
            return [
              stringifyDate(currentValueDate),
              stringifyDate(currentValueDate.plus({ minutes: 15 }))
            ]
          }) : []
      }
    }
  },
  actions: {
    async fetchAirParifData (eventId) {
      state.loading = true
      try {
        const response = await apiService.getAirParifData(eventId)
        store.state.apiData = response.data
        store.state.data = response.data
          .sort((a, b) => a.value_date < b.value_date ? 1 : -1)
          .map(d => {
            const valueDateDisplayed = stringifyDate(DateTime.fromISO(d.value_date), 'HH:mm')
            const thresholds = getPollutantsThreshold(d)
            return {
              indice: {
                value: Math.round(d.data.indice * 10) / 10,
                threshold: thresholds.indice.key,
                label: label[thresholds.indice.key],
                position: thresholds.indice.position
              },
              o3: {
                value: Math.round(d.data.o3 * 10) / 10,
                threshold: thresholds.o3.key,
                label: label[thresholds.o3.key]
              },
              no2: {
                value: Math.round(d.data.no2 * 10) / 10,
                threshold: thresholds.no2.key,
                label: label[thresholds.no2.key]
              },
              pm10: {
                value: Math.round(d.data.pm10 * 10) / 10,
                threshold: thresholds.pm10.key,
                label: label[thresholds.pm10.key]
              },
              pm25: {
                value: Math.round(d.data.pm25 * 10) / 10,
                threshold: thresholds.pm25.key,
                label: label[thresholds.pm25.key]
              },
              valueDate: d.value_date,
              valueDateDisplayed
            }
          })
      } catch (e) {
        console.error(e)
        store.state.error = e
      }
      store.state.loading = false
    },
    resetState () {
      state.data = null
      state.apiData = null
    }
  }
}

export const vrsStoreAirParif = createStore(store)
