/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trend-down': {
    width: 25.512,
    height: 20.878,
    viewBox: '3506.243 -2522.107 25.512 20.878',
    data: '<path pid="0" d="M3529.433-2508.184a1.222 1.222 0 10-2.319-.774l-.89 2.666-2.98-7.437c-.224-.55-.81-.86-1.391-.735l-5.96 1.374-3.093-7.913a1.224 1.224 0 00-2.28.888l3.474 8.89c.218.56.812.879 1.399.751l5.956-1.323 2.815 7.008-3.235-1.08a1.222 1.222 0 00-.774 2.319l5.797 1.934c.143.045.294.064.444.059l.193-.039c.07-.01.14-.026.209-.046a.917.917 0 00.228-.143c.056-.039.109-.081.159-.128a.782.782 0 00.123-.177.953.953 0 00.19-.298z" _fill="#0fa9df"/>'
  }
})
