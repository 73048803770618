import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import { vrsStore } from '../store/'
import router from '../router'

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: SITE_MATOMO_HOST,
  siteId: SITE_MATOMO_ID,

  userId: vrsStore.modules.auth.state.data.user?.username || undefined,

  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Enables automatically registering pageviews on the router
  router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  // requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  // Whether or not to log debug information
  // Default: false
  debug: true
})
