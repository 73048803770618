/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'raindrops': {
    width: 16,
    height: 16,
    viewBox: '0 0 448 512',
    data: '<path pid="0" _fill="currentColor" d="M406.6 252.7c-18.4-25.7-39.2-55-52.6-99.4-4.3-15-17.8-25.1-33.6-25.3-14.4-.3-29.6 10-34.2 25.2-13.4 44.3-34.2 73.6-52.6 99.5-21.3 30-41.5 58.4-41.5 100 0 70.2 57.4 127.3 128 127.3s128-57.1 128-127.3c-.1-41.8-20.2-70.1-41.5-100zM320 448c-52.9 0-96-42.7-96-95.3 0-31.4 15.7-53.5 35.6-81.5 18.8-26.5 42.2-59.4 57.1-108.7.2-.8.8-2.5 3.2-2.5 1.3.5 2.8.7 3.2 2.1l.1.3c15 49.5 38.4 82.4 57.2 108.8 20.6 28.9 35.5 49.8 35.5 81.4.1 52.7-43 95.4-95.9 95.4zm-80-306.1c0-21.4-9.8-35.8-19.2-49.8-7.9-11.6-16-23.6-21.4-42.2-2.9-10.6-12.2-17.8-23.1-17.9-12.4-.1-20.5 7-23.6 17.8-5.3 18.6-13.5 30.7-21.4 42.3-9.5 14-19.3 28.5-19.3 49.8 0 36.4 28.7 66.1 64 66.1s64-29.7 64-66.1zM176 176c-17.7 0-32-15.3-32-34.1 0-11 4.7-18.5 13.8-31.9 5.7-8.5 12.4-18.3 18.2-31.2 5.8 12.9 12.5 22.8 18.3 31.2 9 13.3 13.7 20.7 13.7 31.8 0 18.9-14.3 34.2-32 34.2zm-88.6 49.9c-2.9-10.6-12.2-17.8-23.1-17.9-10.1 1.2-20.5 7-23.6 17.8-5.3 18.6-13.5 30.7-21.4 42.3C9.8 282.1 0 296.6 0 317.9 0 354.3 28.7 384 64 384s64-29.7 64-66.1c0-21.4-9.8-35.8-19.2-49.8-7.9-11.6-16-23.6-21.4-42.2zM64 352c-17.7 0-32-15.3-32-34.1 0-11 4.7-18.5 13.8-31.9 5.7-8.5 12.4-18.3 18.2-31.2 5.8 12.9 12.5 22.8 18.3 31.2 9 13.3 13.7 20.7 13.7 31.8 0 18.9-14.3 34.2-32 34.2z"/>'
  }
})
