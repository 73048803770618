/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sticky-note': {
    width: 16,
    height: 16,
    viewBox: '0 0 448 512',
    data: '<path pid="0" _fill="currentColor" d="M448 348.106V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80v351.988c0 26.51 21.49 48 48 48h268.118a48 48 0 0033.941-14.059l83.882-83.882A48 48 0 00448 348.106zm-120.569 95.196a15.89 15.89 0 01-7.431 4.195v-95.509h95.509a15.88 15.88 0 01-4.195 7.431l-83.883 83.883zM416 80v239.988H312c-13.255 0-24 10.745-24 24v104H48c-8.837 0-16-7.163-16-16V80c0-8.837 7.163-16 16-16h352c8.837 0 16 7.163 16 16z"/>'
  }
})
