/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wind-strength': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M.75 6.693h11.27c1.969 0 3.567-1.502 3.567-3.346C15.587 1.502 13.989 0 12.02 0c-1.97 0-3.567 1.502-3.567 3.347 0 .415.334.749.749.749s.75-.334.75-.75c0-1.017.929-1.847 2.068-1.847 1.138 0 2.068.826 2.068 1.848 0 1.017-.93 1.847-2.068 1.847H.75a.748.748 0 00-.75.75c0 .415.334.75.75.75zm11.27 4.614H.75a.748.748 0 00-.75.75c0 .414.334.749.75.749h11.27c1.138 0 2.068.83 2.068 1.847 0 1.018-.93 1.848-2.068 1.848a.748.748 0 00-.75.75c0 .415.334.749.75.749 1.969 0 3.567-1.502 3.567-3.347.003-1.844-1.598-3.346-3.567-3.346zm5.23-5.198a.748.748 0 00-.749.75c0 .837-.867 1.517-1.932 1.517H.749A.748.748 0 000 9.125c0 .415.334.75.75.75h13.819C16.461 9.874 18 8.521 18 6.857a.748.748 0 00-.75-.749z" _fill="#f4c886"/>'
  }
})
