



































import Vue from 'vue'
import { convertDate } from '@/helpers/dates'
import ForecastOneDay from './ForecastOneDay.vue'
import UpdateTime from '@/components/ui/UpdateTime/UpdateTime.vue'

export default Vue.extend({
  name: 'forecast-daily',
  components: {
    'forecast-one-day': ForecastOneDay,
    'ui-update-time': UpdateTime
  },
  props: {
    daily: {
      type: Object,
      default () {
        return {
          header: '',
          data: []
        }
      }
    },
    defaultForecastInfo: String,
    displayPressure: Boolean,
    apiData: Object,
    currentTime: String,
    timezone: String,
    temperatureOverLimit: {
      type: Number,
      required: false
    },
    chanceOfRainOverLimit: {
      type: Array,
      required: false
    },
    pressureOverLimit: {
      type: Number,
      required: false
    }
  },
  computed: {
    /**
     * Detect whether currentTime is beyond the end of the event.
     */
    currentTimeBeyondEnd () {
      const currentDateTime = convertDate(this.currentTime, this.timezone)
      let currentTimeBeyondEnd = false
      if (this.apiData &&
        this.apiData.range &&
        this.apiData.range[1] &&
        currentDateTime > this.apiData.range[1]
      ) {
        currentTimeBeyondEnd = true
      }
      return currentTimeBeyondEnd
    },
    noDailyData () {
      return !Array.isArray(this.daily.data) || this.daily.data.length === 0
    }
  }
})

