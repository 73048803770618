<template>
  <div class='GroundStationsMap flex flex-col h-full '>

    <header>
      <div class='title text-2xl mb-2'>
        Ground stations
      </div>
    </header>

    <ui-loader v-if='loading' />
    <main class="flex-grow flex flex-col md:flex-row lg:flex-col">
      <div class="flex flex-col items-center" v-if="stations.length > 0">
        <station-detail
          v-for="(station, index) in stations"
          class="station-detail relative mb-2 z-1000"
          :class="station.properties.position"
          :reference="station.properties.reference"
          :key="station.id"
          v-bind="stationsData[index]"
          variant="tiny"
          :displayHumidity="false"
          :displayPressure="false"
        />
      </div>
      <div v-else class="flex flex-col items-center">
        No station available.
      </div>
      <draw-track
        :id="id"
        class="flex-grow"
        :geojson="geojson"
        :stations="stations"
        :location="location"
      />
    </main>
  </div>
</template>

<script>
import Loader from '@/components/ui/Loader/Loader.vue'
import WeatherStationDetailVue from './WeatherStationDetail.vue'
import Track from '@/components/data/common/Track/Track.vue'

export default {
  name: 'GroundStationsMap',
  components: {
    'ui-loader': Loader,
    'draw-track': Track,
    'station-detail': WeatherStationDetailVue
  },
  props: {
    location: {
      type: Object,
      required: true
    },
    stations: {
      type: Array,
      default: () => ([])
    },
    geojson: {
      type: Object,
      required: true
    },
    stationsData: {
      type: Array,
      default: () => ([])
    },
    loading: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'gs-map'
    }
  }
}
</script>

<style lang="postcss" scoped>

.station-detail {
  /* position: absolute; */
  /* background-color: #29385FC9; */
  background-color: var(--color-temperature-session-bg);
}
/* .station-detail.left {
  left: 0.25rem;
  top: 0.25rem;
}
.station-detail.right {
  right: 0.25rem;
  bottom: 0.25rem;
} */
</style>
