/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'trend-up': {
    width: 22.069,
    height: 14.763,
    viewBox: '3515.964 -2767.882 22.069 14.763',
    data: '<path pid="0" _fill="currentColor" d="M3537.96-2766.66a.953.953 0 000-.256.782.782 0 00-.063-.208 1.344 1.344 0 00-.11-.17.916.916 0 00-.172-.207l-.147-.085a.843.843 0 00-.233-.121l-.244.001a.855.855 0 00-.258-.17l-6.108.03a1.222 1.222 0 10.012 2.442l3.457-.016-4.86 5.777-5.292-3.115a1.222 1.222 0 00-1.562.276l-6.073 7.359a1.222 1.222 0 00.95 1.999c.363-.001.707-.164.938-.444l5.406-6.55 5.23 3.103c.513.3 1.167.19 1.55-.264l5.152-6.071.013 2.81a1.222 1.222 0 102.443-.012l-.028-6.108z"/>'
  }
})
