import { render, staticRenderFns } from "./AirParif.vue?vue&type=template&id=08c31ac8&scoped=true&"
import script from "./AirParif.vue?vue&type=script&lang=js&"
export * from "./AirParif.vue?vue&type=script&lang=js&"
import style0 from "./AirParif.vue?vue&type=style&index=0&id=08c31ac8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c31ac8",
  null
  
)

export default component.exports