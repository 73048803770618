/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'wind-dir': {
    width: 18.986,
    height: 18.987,
    viewBox: '3310 -2669 18.986 18.987',
    data: '<path pid="0" d="M3328.851-2659.63c-.236-.915-.763-1.675-1.484-2.14 0 0-8.94-5.773-9.04-5.823-.446-.229-1.565-.109-1.624-.101l-5.28.891v-1.407c0-.437-.319-.79-.71-.79-.395 0-.713.353-.713.79v17.406c0 .438.318.79.71.79.39 0 .708-.352.708-.79v-12.918l2.325 4.173c.26.434.376.643.891.906.101.05 9.854 3.848 9.854 3.848.787.306 1.647.256 2.426-.14.763-.387 1.383-1.08 1.74-1.952.36-.868.43-1.84.197-2.744zm-14.914-4.79c-.186.453-.33.915-.434 1.372l-1.31-2.275 2.43-.41c-.264.399-.492.84-.686 1.313zm1.406 4.375s-.325-.047-.511-.698c-.174-.705-.078-1.852.399-3.014.698-1.69 1.895-2.666 2.48-2.399l.116.074c.492.426.566 2-.097 3.607-.658 1.604-1.778 2.565-2.387 2.43zm4.414 1.72l-2.441-.953c.678-.608 1.282-1.5 1.712-2.538.43-1.038.644-2.131.613-3.1l2.224 1.434c-.008.821-.237 1.84-.64 2.813-.39.957-.922 1.802-1.468 2.344zm3.731 1.453l-2.208-.86c.46-.64.887-1.407 1.243-2.274a11.9 11.9 0 00.744-2.573l2.03 1.31c.036.708-.135 1.588-.48 2.417-.34.83-.825 1.55-1.329 1.98zm3.875-.678c-.422 1.027-1.426 1.573-2.317 1.283a9.044 9.044 0 001.07-1.922c.294-.713.5-1.45.608-2.158.779.565 1.062 1.774.64 2.797z" _fill="#f4c886"/>'
  }
})
