// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
let hidden
let visibilityChange

if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.mozHidden !== 'undefined') {
  hidden = 'mozHidden'
  visibilityChange = 'mozvisibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

export default onPageVisibilityChange => ({
  mounted () {
    document.addEventListener(visibilityChange, this.handleVisibilityChange, false)
  },
  beforeDestroy () {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange)
  },
  methods: {
    handleVisibilityChange () {
      onPageVisibilityChange(!document[hidden])
    }
  }
})
