







































import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  name: 'LightIntensity',
  components: {
    'ui-loader': Loader
  },
  props: {
    loading: Boolean,
    avgLightIntensity: {
      type: Number
    },
    value_date: {
      type: String
    }
  },
  computed: {
    valueDateDisplay () {
      if (!this.value_date) return
      return this.value_date.substring(
        this.value_date.indexOf('T') + 1,
        this.value_date.indexOf('T') + 6
      )
    },
    averageToDisplay () {
      if (this.avgLightIntensity && this.avgLightIntensity >= 0) {
        return Math.round(this.avgLightIntensity)
      }
      return '--'
    },
    offsetCylinder () {
      if (this.avgLightIntensity >= 10000) {
        return 1
      } else if (this.avgLightIntensity >= 1000) {
        return 0.6
      } else if (this.avgLightIntensity >= 350) {
        return 0.5
      } else if (this.avgLightIntensity >= 150) {
        return 0.4
      } else if (this.avgLightIntensity >= 80) {
        return 0.3
      } else if (this.avgLightIntensity >= 50) {
        return 0.2
      } else if (this.avgLightIntensity >= 5) {
        return 0.1
      }
      return 0
    }
  }
})

