import Vue from 'vue'
import router from '../router'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        beforeNavigate: context => {
          return {
            ...context,
            // You could use your UI's routing library to find the matching
            // route template here. We don't have one right now, so do some basic
            // parameter replacements.
            name: router.currentRoute.path
          }
        }
      })
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,

    // debug: true,
    environment: SENTRY_ENV,
    release: 'mfs-frontend@' + MFS_APP_VERSION.toString(),
    Vue,
    tracingOptions: {
      trackComponents: true
    }
  })
}
