



















import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  name: 'WeatherStationAverageTemperature',
  components: {
    'ui-loader': Loader
  },
  props: {
    loading: Boolean,
    average: {
      type: Number
    },
    /**
     * actual format : YYYY-MM-DDTHH:mm:ss
     */
    value_date: {
      type: String,
      default: ''
    }
  },
  computed: {
    valueDateDisplay () {
      if (!this.value_date) return '--'
      return this.value_date.substring(
        this.value_date.indexOf('T') + 1,
        this.value_date.indexOf('T') + 6
      )
    },
    averageToDisplay () {
      if (!this.average) return '--'
      return Math.round(this.average * 10) / 10
    }
  }
})

