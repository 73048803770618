import { StateApp } from './definitions'
import Bowser from 'bowser'
import { stringifyDate, getNowDateTime } from '@/helpers/dates'
import preferences from '@/services/preferences'
import { createStore } from 'vue-reactive-store'
import crossbarService from '@/services/crossbar'

const breakpointLG = 1024
const breakpointFontSize = 1921 // colors['breakpoint-lg'] // 1367
const breakpointForecastItemWidth = 768 // colors['breakpoint-xs'] // 768

const browser = Bowser.getParser(window.navigator.userAgent)

function getSliderItemWidth (innerWidth: number) {
  if (innerWidth < breakpointForecastItemWidth) {
    return 14 * 6
  } else if (innerWidth < breakpointFontSize) {
    return 14 * 8
  } else {
    return 16 * 8
  }
}

const state: StateApp = {
  loading: false,
  data: {
    eventId: null,
    eventTimezone: null, // timezone of the current event
    live: null,
    radarRange: null,
    referenceDate: null,
    animationLength: null,
    currentPageDefaultAnimationLength: 30, // for nowcasting by default
    defaultAnimationLength: preferences.defaultAnimationLength,
    maximumTimeLive: null,
    settings: null,
    isMobile: window.innerWidth <= breakpointLG,
    isDesktop: browser.getPlatformType() === 'desktop',
    isiOS: browser.getOSName() === 'iOS',
    isAndroid: browser.getOSName() === 'Android',
    fontBase: window.innerWidth < breakpointFontSize ? 14 : 16,
    sliderItemWidth: getSliderItemWidth(window.innerWidth),
    queryString: {},
    zoomTimeline: null,
    replaySettings: {
      enabled: true,
      weatherstation: false,
      weatherstationavgtemp: false,
      forecast: false,
      information: false
    }
  },
  error: null
}
const store = {
  name: 'app',
  state,
  computed: {
    mapComponentToUse () {
      if (state.data.isiOS === true) return 'map-mapbox'
      if (state.data.isAndroid === true) return 'map-leaflet'
      if (state.data.isDesktop === true) return 'map-leaflet'
      return 'map-mapbox'
    },
    imageResolution () {
      // we have to implement half_image.png too on server before use it with isiOS / isAndroid / isDesktop info
      if (state.data.isiOS === true) return '/half_image.png'
      if (state.data.isAndroid === true) return '/half_image.png'
      if (state.data.isDesktop === true) return '/full_image.png'
      return '/full_image.png'
    }
  },
  watch: {
    'data.live' (newValue: boolean) {
      if (newValue === true) {
        crossbarService.enable()
      } else {
        crossbarService.disable()
      }
    }
  }
}

/**
 * Update the maximum date for live event
 * with timezone in param
 * @param timezone Zone
 */
let timeoutMaximumTimeLive: number
export function startUpdateMaximumTimeLive (timezone = 'utc') {
  store.state.data.maximumTimeLive = stringifyDate(getNowDateTime().setZone(timezone))
  timeoutMaximumTimeLive = setTimeout(() => startUpdateMaximumTimeLive(timezone), 1000)
}

/**
 * Remove the timeout
 */
export function stopUpdateMaximumTimeLive () {
  if (timeoutMaximumTimeLive) clearInterval(timeoutMaximumTimeLive)
}

/**
 * Update info about sizes :
 * * isMobile allow us to know if we are on a 'small device'
 * * fontBase is about the font size
 * * sliderItemWidth is about the size of an item in forecast by hour display
 */
function updateSizes () {
  store.state.data.isMobile = window.innerWidth < breakpointLG
  store.state.data.fontBase = window.innerWidth < breakpointFontSize ? 14 : 16
  store.state.data.sliderItemWidth = getSliderItemWidth(window.innerWidth)
}
window.addEventListener('resize', updateSizes)

export const vrsStoreApp = createStore(store)
