









































import Vue from 'vue'
export default Vue.extend({
  props: {
    displayHumidity: {
      type: Boolean,
      default: false
    },
    displayPressure: {
      type: Boolean,
      default: true
    },
    displayGust: {
      type: Boolean,
      default: true
    },
    displayTrackMinMax: {
      type: Boolean,
      default: false
    },
    displayChanceOfRain: {
      type: Boolean,
      default: false
    },
    displaySession: {
      type: Boolean,
      default: false
    }
  }
})
