
































































/* global VIEW_HOME_BACKGROUND_IMAGE */
import Logos from '@/components/ui/Logos/Logos.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import FormLogin from '@/components/forms/Login.vue'

import { vrsStore } from '@/store'
import { vrsStoreEvent } from '@/store/event/store'
import { forecastDataExist } from '@/store/event/helpers'
import preferences from '@/services/preferences'

export default {
  name: 'Home',
  components: {
    'ui-logos': Logos,
    'ui-loader': Loader,
    FormLogin
  },
  data () {
    return {
      auth: vrsStore.state.auth,
      config: vrsStore.state.config,
      version: MFS_APP_VERSION,
      backgroundImage: VIEW_HOME_BACKGROUND_IMAGE
    }
  },
  computed: {
    logos: vrsStore.modules.config.computed.logos,
    logosMobile: vrsStore.modules.config.computed.logosMobile
  },
  mounted () {
    preferences.lastURL = ''
    vrsStore.modules.auth.state.error = null
  },
  methods: {
    async handleSubmit ({ username, password }) {
      await vrsStore.modules.auth.actions.login(username, password)
      const { isAuthenticated, user } = vrsStore.modules.auth.state.data
      if (isAuthenticated) {
        this.$matomo && this.$matomo.setUserId(user.username)
        if (this.$route.query.redirect) {
          return this.$router.push(this.$route.query.redirect)
        } else {
          if (vrsStore.modules.auth.computed.canAccessToForecastClimate()) {
            // No access to Event Active  need to call api
            await vrsStoreEvent.actions.fetchAllEvents()

            if (forecastDataExist(vrsStore.modules.event.state.activeEvent.id, vrsStore.modules.event.state.events)) {
              return this.$router.push('/event/active/forecast')
            } else {
              return this.$router.push('/event/active/climaterecap')
            }
          }
          return this.$router.push(vrsStore.modules.auth.computed.defaultHomePage())
        }
      }
    }
  }
}
