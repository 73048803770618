import { DateTime } from 'luxon'
import {
  convertDate,
  stringifyDate,
  convertDateStringToNoTimezoneDate,
  isTimeInRange
} from '@/helpers/dates'
import { D3TimelineSerie } from '@/components/ui/Timeline/D3Timeline/timeline'

/**
 * Construct an array of utc string
 * @param dateFrom
 * @param dateTo
 */
export function getTimesToDisplay (
  dateFrom: string,
  dateTo: string,
  timezone?: string
): Array<string> {
  const result = []
  const dateFromLuxon: DateTime = convertDate(dateFrom, timezone)
  const dateToLuxon: DateTime = convertDate(dateTo, timezone)
  // let dateFromLuxonUTC: DateTime = dateFromLuxon.toUTC()
  for (let time: DateTime = dateFromLuxon; time <= dateToLuxon; time = time.plus({ minutes: 1 })) {
    // result.push({
    // local: stringifyDate(time),
    // title: `${time.toFormat('D')} ${time.toFormat('T')}`,
    // display: time.toFormat('T')
    // utc: stringifyDate(dateFromLuxonUTC)
    // })
    // dateFromLuxonUTC = dateFromLuxonUTC.plus({ minutes: 1 })
    result.push(stringifyDate(time))
  }
  return result
}

/**
 * Converts a list of times to a list of time ranges.
 *
 * Merges a list of times to multiple corresponding ranges of times by grouping
 * together all items that are timely close, depênding on the number of seconds
 * representing gaps (see param secondsForGaps).
 *
 * @param times: List of times as datestrings (e.g.: ['20181017083000', '20181017083100', ...]).
 *   They must be already ordered ascending.
 * @param secondsForGaps: The minimum number of seconds between two available dates that
 *   triggers a gap in the list of ranges.
 * @returns
 *   List of corresponding ranges. Structure:
 *   [
 *     [
 *       // Start time of the range, formatted as datestring 'yyyyMMddHHmmss'.
 *       string,
 *       // End time of the range, formatted as datestring 'yyyyMMddHHmmss'.
 *       string,
 *     ],
 *     [ ... ],
 *     ...
 *   ]
 */
export function getTimeRanges (times: string[], secondsForGaps: number): Array<[string, string]> {
  const timeRanges = []

  let currentRangeStartDString = null
  let currentRangeEndDString = null

  // Loop over the list of time and merge the ones that are close together.
  for (let i = 0; i < times.length; i++) {
    const currentDString = times[i]

    if (currentRangeStartDString === null) {
      currentRangeStartDString = currentDString
    }

    if (currentRangeEndDString === null) {
      currentRangeEndDString = currentDString
    }

    const endTimeObj = convertDateStringToNoTimezoneDate(currentRangeEndDString)
    const currentTimeObj = convertDateStringToNoTimezoneDate(currentDString)

    const diffSecondsObj = (currentTimeObj.getTime() - endTimeObj.getTime()) / 1000

    if (diffSecondsObj < secondsForGaps) {
      // If time between previous "rangeEnd" and current time is lower than
      // the time defined for gaps, keep going.
      currentRangeEndDString = currentDString
    } else {
      // Otherwise, it means we found a large enough time diff: Create a gap.
      // Record the previous range.
      timeRanges.push([
        currentRangeStartDString,
        currentRangeEndDString
      ])

      // Start a new range of values.
      currentRangeStartDString = currentDString
      currentRangeEndDString = currentDString
    }
  }

  // End of loop, record the final range, if any started.
  if (currentRangeStartDString !== null && currentRangeEndDString !== null) {
    timeRanges.push([
      currentRangeStartDString,
      currentRangeEndDString
    ])
  }

  return timeRanges
}

/**
 * Check if the first range is inside the second
 */
export function isRangeInsideAnotherRange (
  rangeToCheck: [DateTime, DateTime],
  range: [DateTime, DateTime]
): boolean {
  return (
    rangeToCheck[0] >= range[0] && rangeToCheck[0] <= range[1] &&
    rangeToCheck[1] >= range[0] && rangeToCheck[1] <= range[1]
  )
}

/**
 * Returns an array of default selected range, for Timeline purpose.
 * If we are live, return a range [now - period, now]
 *
 * @param availableTimes Array of all time entries available, as datestrings 'yyyyMMddHHmmss'.
 * @param wishedRange Array of the range wished.
 * @param durationInMinutes Default range duration in minutes if queryUrl.currentTime was not provided.
 * Defaults to storeApp.data.defaultAnimationLength (30 mn)
 * Will be set to `15min` if you provide a value lower than the latter.
 * @param live is the event in live or not
 * @returns A two datestring array: first element being the range start, the
 *   second being the range end. If no range could be determined, it will return a `minimumRangeDurationInMinutes` minutes range
 *   with start date set to the current date and time.
 */
const minimumRangeDurationInMinutes = 15
export function getDefaultSelectedRange (
  availableRange: [string, string], /** DateTime strings in yyyyMMddHHmmss */
  referenceDate: string = null,
  durationInMinutes: number = minimumRangeDurationInMinutes,
  live = false,
  maximumTimeLive: string = null
): [string, string] {
  if (durationInMinutes < minimumRangeDurationInMinutes) {
    durationInMinutes = minimumRangeDurationInMinutes
  }

  const firstAvailableDString = availableRange[0]
  let latestAvailableDString = availableRange[1]
  if (maximumTimeLive && maximumTimeLive < latestAvailableDString) {
    latestAvailableDString = maximumTimeLive
  }

  let rangeStartDString
  let rangeEndDString
  // If we aren't in live
  if (!live) {
    rangeEndDString = referenceDate || latestAvailableDString
    if (rangeEndDString > latestAvailableDString) {
      rangeEndDString = latestAvailableDString
    }
    rangeStartDString = stringifyDate(
      convertDate(rangeEndDString, null)
        .minus({ minutes: durationInMinutes })
    )
    if (rangeStartDString < firstAvailableDString) {
      rangeStartDString = firstAvailableDString
    }
  } else {
    // if live is true
    // we compute the interval with the latest available image
    rangeEndDString = latestAvailableDString
    rangeStartDString = stringifyDate(
      convertDate(rangeEndDString, null)
        .minus({ minutes: durationInMinutes })
    )
  }
  return [rangeStartDString, rangeEndDString]
}

/**
 * Returns the range that intersect a range and a series of range
 *
 * @param range
 * Initial range to check
 *
 * @param timeRanges
 * Ranges to intersect with
 *
 * @returns
 *  A two datestring array:
 *   * first element being the range start
 *   * second being the range end
 *  If no range could be determined, it's [null, null]
 */
export function calcIntersectionBetweenRangeAndTimeRanges (
  range: [string, string],
  timeRanges: D3TimelineSerie
): [string, string] {
  const result: [string, string] = [null, null]
  const firstDate: string = range[0]
  const lastDate: string = range[1]
  let firstDateFlag = false
  let lastDateFlag = false
  let firstRangeAfterFirstDate: [string, string] = null
  let firstRangeBeforeLastDate: [string, string] = null
  timeRanges.data.forEach((currentRange: [string, string]) => {
    if (!firstDateFlag) {
      firstDateFlag = isTimeInRange(firstDate, currentRange)
      if (firstDateFlag) {
        result[0] = firstDate
      }
    }
    if (!firstRangeAfterFirstDate && firstDate < currentRange[0]) {
      firstRangeAfterFirstDate = currentRange
    }
    if (!lastDateFlag) {
      lastDateFlag = isTimeInRange(lastDate, currentRange)
      if (lastDateFlag) {
        result[1] = lastDate
      }
    }
    if (lastDate > currentRange[1]) {
      firstRangeBeforeLastDate = currentRange
    }
  })
  // if we don't have a range for the first date
  // but if we found a range after
  // use the first date of this range instead
  if (!firstDateFlag && firstRangeAfterFirstDate) {
    result[0] = firstRangeAfterFirstDate[0]
  }
  // if we don't have a range for the last date
  // but if we found a range before
  // use the last date of this range
  if (!lastDateFlag && firstRangeBeforeLastDate) {
    result[1] = firstRangeBeforeLastDate[1]
  }
  // if one of the date is null,
  // or if the lastDate is before the firstDate,
  // return null
  if (!result[0] || !result[1] || result[1] < result[0]) return null
  return result
}

/**
 * Tests whether given range contains gaps.
 * @param {String} startDatestring: Range start, formatted as 'yyyyMMddHHmmss'.
 * @param {String} endDatestring: Range end, formatted as 'yyyyMMddHHmmss'.
 * @param {Object[]} timeRanges: List of ranges, as returned by getTimeRanges().
 * @returns {Boolean}: True if there are gaps between start & end.
 */
export function rangeContainsGaps (startDatestring, endDatestring, timeRanges) {
  // Loop over ranges, to check whether start and end are part of a common range or not.
  for (let i = 0; i < timeRanges.data.length; i++) {
    const timeRange = timeRanges[i]
    const startInRange = isTimeInRange(startDatestring, [timeRange.start, timeRange.end])
    const endInRange = isTimeInRange(endDatestring, [timeRange.start, timeRange.end])

    if (startInRange && endInRange) {
      // If start and end are within current range, it means there are no gaps
      // between them.
      return false
    } else if ((startInRange && !endInRange) || (!startInRange && endInRange)) {
      // If one of start/end is out of current range, it means there is at least
      // a gap between start & end.
      return true
    }
  }

  // If we reach this line, it means start & end are either both out of a range or
  // that the list of time ranges was empty. Either way, it means that some data is
  // missing between start & end, so tell the caller there are gaps.
  return true
}

/**
 * Returns an array of date range relative to currentTime
 * If initial dateStart is before currentTime and range is higher than minRangeDuration,
 * we return initial dateStart and currentTime
 *
 * But if initial dateStart is after currentTime or date range is too low,
 * we returns an array of currentTime - minRangeDuration and currentTime
 *
 * @param {string[]} dateRange - Range of available dates
 * @param {string[]} newSelectedRange
 * @param {number} [minRangeDuration=30]
 * @returns {string[]} Array of datestring
 */
export function getRangeFromCurrentTime (dateRange, newSelectedRange, timezone, minRangeDuration = 60) {
  // If newSelectedRange is not in dateRange, returns a new range array based on dateRange + min duration
  if (newSelectedRange[0] < dateRange[0] || newSelectedRange[1] > dateRange[1]) {
    const dateEndLocalBasedOnDateRange = convertDate(dateRange[0], timezone).plus({ minutes: minRangeDuration })
    return [dateRange[0], stringifyDate(dateEndLocalBasedOnDateRange)]
  }
  const luxonDateStart = convertDate(newSelectedRange[0], timezone)
  const luxonDateEnd = convertDate(newSelectedRange[1], timezone)
  const minDateStart = luxonDateEnd.minus({ minutes: minRangeDuration })

  // If new currentTime (newSelectedRange[1]) is after initial dateStart (newSelectedRange[0])
  // we returns it, it's ok.
  if (luxonDateStart < luxonDateEnd &&
    minDateStart <= luxonDateEnd) {
    return newSelectedRange
  }

  const dateStartFromCurrentTime = stringifyDate(minDateStart)
  return [dateStartFromCurrentTime, newSelectedRange[1]]
}
