






















































import Loader from '@/components/ui/Loader/Loader.vue'
import Button from '@/components/ui/Button/Button.vue'

export default {
  name: 'FormLogin',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  components: {
    'ui-button': Button,
    'ui-loader': Loader
  },
  data: () => ({
    username: '',
    password: ''
  }),
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.$emit('submit', { username: this.username, password: this.password })
    }
  }
}
