<template>
  <div class='ActiveEvent'>
    <p class='ActiveEvent__title'>Current event</p>
    <p class='ActiveEvent__label'>
      {{activeEvent.label}}
    </p>
    <p class='ActiveEvent__period'>
      {{activeEvent.period}}
    </p>
    <ui-button
      @click="() => this.$emit('goToActive', this.activeEvent.id)"
      contained
    >
      Go
    </ui-button>

  </div>

</template>

<script>
import Button from '@/components/ui/Button/Button.vue'
export default {
  name: 'active-event',
  props: ['activeEvent'],
  components: {
    'ui-button': Button
  }
}
</script>

<style lang="postcss" scoped>

.ActiveEvent {
  display: flex;
  flex-direction: column;
  color: var(--color-font);
  padding: $default-spacing;
  &__title {
   font-weight: bold;
   font-size: .875em;
  }
  &__label {
    padding-top: .5em;
    text-align: center;
    font-size: 1.2em;
  }
  &__period {
    text-align: center;
    color: var(--color-event-period);
  }
  .Button {
    margin-top: 0.5em;
    width: 100%;
  }
}

</style>
