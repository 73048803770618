// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { MessageData, Messages } from './definitions'
import apiService from '@/services/api'
import { createStore } from 'vue-reactive-store'

const store = {
  name: 'message',
  state: {
    loading: false,
    error: null,
    /**
     * @type {Messages}
     */
    data: null,
    apiData: []
  },
  actions: {
    /**
     * Fetch all messages for an event
     */
    async fetchMessages (eventId: number | string) {
      store.state.loading = true
      try {
        const responseMessages = await apiService.getMessages(eventId)
        store.state.apiData = responseMessages.data

        // build an array of championship, if exist
        // each element of the array contain all messages for a championship
        const result: { [key: string]: MessageData[] } = {}
        responseMessages.data.forEach(d => {
          // for old messages, there isn't championship
          // we have to store 'empty championship'
          const currentMessage = d.data.RaceWatchMessage
          const currentChampionship = currentMessage.Championship?.$ || ''
          if (!result[currentChampionship]) result[currentChampionship] = []
          result[currentChampionship].push({
            timestamp: currentMessage['@Timestamp'],
            message: currentMessage.Text.$,
            championship: currentChampionship
          })
        })

        // then revamp it to match a simple array of championship
        store.state.data = Object.keys(result).sort().map(key => {
          return {
            label: key,
            messages: result[key].sort((a, b) => b.timestamp.localeCompare(a.timestamp))
          }
        })
      } catch (e) {
        console.error(e)
        store.state.error = e
      }
      store.state.loading = false
    }

  }
}

export const fetchMessages = store.actions.fetchMessages

export const vrsStoreMessages = createStore(store)
