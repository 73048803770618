


















import Loader from '@/components/ui/Loader/Loader.vue'

export default {
  name: 'ui-button',
  components: {
    'ui-loader': Loader
  },
  props: [
    'active',
    'type',
    'title',
    'variant',
    'loading',
    'disabled',
    'contained',
    'twostep',
    'twostepclass',
    'twosteptitle'
  ],
  data () {
    return {
      status: 0,
      twostepTimeoutId: null
    }
  },
  methods: {
    onClick ($event) {
      /**
       * Add a second step before emitting the click
       */
      if (this.twostep) {
        if (this.status === 0) {
          this.status++
          this.twostepTimeoutId = setTimeout(() => {
            this.status = 0
          }, 2000)
          return
        }
      }
      this.$emit('click', $event)
      this.status = 0
    }
  },
  beforeDestroy () {
    this.twostepTimeoutId && clearTimeout(this.twostepTimeoutId)
  }
}

