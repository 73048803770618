












export default {
  props: {
    logos: {
      type: Array,
      default: () => ([])
    }
  }
}
