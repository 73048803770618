import apiService from '@/services/api'
import { extractDataFromEvents, extractDataFromSingleEvent, getSingleEventDates } from './helpers'
import { WeatherEvent, StateEvent } from './definitions'
import { createStore } from 'vue-reactive-store'
import { startUpdateMaximumTimeLive, stopUpdateMaximumTimeLive, vrsStoreApp } from '@/store/app/store'
import crossbarService from '@/services/crossbar'
import { DateTime } from 'luxon'

const state: StateEvent = {
  loading: false,
  loadingAllEvents: false,
  loadingClimateRecap: false,
  loadingClimateRecapCompletion: null,
  error: null,
  data: null,
  seasons: [],
  events: [],
  activeEvent: null,
  sessionsWithStat: []
}

const store = {
  name: 'event',
  state,
  actions: {
    /**
     * Fetch the event asked by his id
     * Geometry included
     *
     * @param eventId
     *  Id of the event
     * @param forceFetching
     *  Force fetch for specific event (even if data already exist)
     */
    async fetchEvent (eventId: number | string, forceFetching = false) {
      const stateData = store.state.data
      const dataExists = stateData && (stateData.id === eventId || (stateData.active && eventId === 'active'))
      // if we already have data for the `eventId` event, we return
      if (!forceFetching && dataExists) {
        return
      }

      store.state.loading = true
      try {
        const activeEventResponse = await apiService.getEvent(eventId)
        store.state.data = extractDataFromSingleEvent(activeEventResponse.data)
      } catch (error) {
        console.error(error)
        store.state.error = error
      }
      store.state.loading = false
    },

    /**
     * Fetch the event asked by his id to get statistics data according to session
     * Geometry included
     */
    async fetchEventWithStatisticData () {
      store.state.loadingClimateRecap = true
      store.state.loadingClimateRecapCompletion = 0
      store.state.sessionsWithStat = []
      try {
        let allRelatedEvents = []
        // Get all event linked to selected event
        if (store.state.events.length === 0) {
          const res = await apiService.getEventsByCode(store.state.data.code)
          allRelatedEvents = res.data
        } else {
          allRelatedEvents = store.state.events.filter(
            currentEvent => (currentEvent.code === store.state.data.code)
          )
        }
        if (store.state.data) {
          const completionFrame = Math.trunc(100 / allRelatedEvents.length)
          // Get data for all related event
          const promises = allRelatedEvents.map(event => {
            return apiService.getAllWeatherStationDataForEventSessions(event.id)
              .then(response => {
                store.state.loadingClimateRecapCompletion += completionFrame
                return response
              })
          })
          const responses = await Promise.all(promises)
          store.state.loadingClimateRecapCompletion = 100

          const results = responses.map((r, index) => {
            const relatedEvent = allRelatedEvents[index]
            const currentEventPeriod = getSingleEventDates(
              relatedEvent.start_date,
              relatedEvent.end_date
            )
            const sessions = r.data.map(session => {
              const labelAndPeriod = session.label.split('-')
              return ({
                ...session,
                labelDisplay: labelAndPeriod[0].trim(),
                period: labelAndPeriod.length > 1 ? labelAndPeriod[1].trim() : 'not defined',
                sessionDateStart: DateTime.fromFormat(session.start.toString(), 'yyyyMMddHHmmss').toFormat('MM/dd'),
                sessionTimestampStart: DateTime.fromFormat(session.start.toString(), 'yyyyMMddHHmmss').valueOf(),
                stations: session.stations.sort((a, b) => a.idStation.toString() - b.idStation.toString())
              })
            })

            return {
              ...relatedEvent,
              label: `${relatedEvent.year} (${currentEventPeriod.period})`,
              timestampStart: new Date(relatedEvent.start_date).valueOf(),
              sessions
            }
          })

          store.state.sessionsWithStat = results.sort((a, b) => a.timestampStart - b.timestampStart)
          store.state.loadingClimateRecap = false
        }
      } catch (error) {
        store.state.error = error
        store.state.loadingClimateRecap = false
      }
    },

    /**
     * Fetch all the events available from the backend,
     * all seasons included
     * Geometry not included
     */
    async fetchAllEvents () {
      store.state.loadingAllEvents = true
      try {
        const allEventsResponse = await apiService.getEvents()
        const events = extractDataFromEvents(allEventsResponse.data)
        store.state.seasons = events.seasons
        store.state.events = events.events
        store.state.activeEvent = events.activeEvent
      } catch (error) {
        console.error(error)
        store.state.error = error
      }
      store.state.loadingAllEvents = false
    }
  },
  watch: {
    data (newValue: WeatherEvent) {
      if (newValue?.active === true) {
        startUpdateMaximumTimeLive(newValue.timezone)
        if (vrsStoreApp.state.data.live) crossbarService.enable()
      } else {
        crossbarService.disable()
        stopUpdateMaximumTimeLive()
      }
    }

  }
}

export const fetchEvent = store.actions.fetchEvent

export const fetchAllEvents = store.actions.fetchAllEvents

export const fetchEventWithStatisticData = store.actions.fetchEventWithStatisticData

export const vrsStoreEvent = createStore(store)
