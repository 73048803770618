<template>
  <div
    class="flex items-center justify-around border-l border-solid px-1 overflow-hidden"
  >
    <p class="hidden lg:block text-left pr-2">
      Air<br>
      Quality<br>
      <strong v-if="valueDateDisplayed">{{ valueDateDisplayed }}</strong>
    </p>
    <template
      v-if="valueDateDisplayed"
    >
      <div
        class="flex flex-col items-center justify-around h-full"
      >
        <button
          class="flex flex-col md:flex-row items-center md:items-baseline"
          :title="indice.value"
          @click='toggleDetails'
        >
          <span class="md:hidden text-lg">
            {{ indice.label }}
          </span>
          <span class="indice-color md:mr-1 w-6 h-6 md:w-4 md:h-4" :class="indice.threshold">
          </span>
          <span class="hidden md:block md:text-2xl">
            {{ indice.label }}
          </span>
          <span class="text-sm sm:text-base">
            ({{ valueDateDisplayed }})
          </span>
        </button>
        <div class="hidden md:block h-1 w-100 airparif-legend relative" :title="indice.value">
          <span
            class="absolute -top-1/2 rounded border border-black border-solid bg-white h-2 w-2"
            :style="{
              left: indice.position
            }"
          />
        </div>
        <!-- Desktop section -->
        <div class="hidden md:flex justify-around">
          <span class="mx-1" :title="no2.label + ' (' + no2.value + ' µg/m³)'">
            <span class="indice-color md:w-4 md:h-4 mr-1" :class="no2.threshold"></span>NO₂
          </span>
          <span class="mx-1" :title="pm10.label + ' (' + pm10.value + ' µg/m³)'">
            <span class="indice-color md:w-4 md:h-4 mr-1" :class="pm10.threshold"></span>PM₁₀
          </span>
          <span class="mx-1" :title="pm25.label + ' (' + pm25.value + ' µg/m³)'">
            <span class="indice-color md:w-4 md:h-4 mr-1" :class="pm25.threshold"></span>PM₂.₅
          </span>
          <span class="mx-1" :title="o3.label + ' (' + o3.value + ' µg/m³)'">
            <span class="indice-color md:w-4 md:h-4 mr-1" :class="o3.threshold"></span>O₃
          </span>
        </div>
      </div>
      <!-- RWD section -->
      <div
        class='absolute bg-background md:hidden left-0 right-0 p-4 transition duration-200 flex flex-col sm:static sm:p-0 justify-around border-b border-solid'
        :class="{
          'hidden': !isOpen,
          'top-18': isOpen,
          '-top-100': !isOpen,
        }"
      >
        <div class="my-2 h-2 w-100 airparif-legend relative" :title="indice.value">
          <span
            class="absolute -top-1/2 rounded border border-black border-solid bg-white h-4 w-4"
            :style="{
              left: indice.position
            }"
          />
        </div>
        <div class="flex justify-around mt-4">
          <div class="mx-1 center" :title="no2.label + ' (' + no2.value + ' µg/m³)'">
            <span class="indice-color w-4 h-4 mr-1" :class="no2.threshold"></span>NO₂
            <br>
            {{  no2.label }}
          </div>
          <div class="mx-1 center" :title="pm10.label + ' (' + pm10.value + ' µg/m³)'">
            <span class="indice-color w-4 h-4 mr-1" :class="pm10.threshold"></span>PM₁₀
            <br>
            {{  pm10.label }}
          </div>
          <div class="mx-1 center" :title="pm25.label + ' (' + pm25.value + ' µg/m³)'">
            <span class="indice-color w-4 h-4 mr-1" :class="pm25.threshold"></span>PM₂.₅
            <br>
            {{  pm25.label }}
          </div>
          <div class="mx-1 center" :title="o3.label + ' (' + o3.value + ' µg/m³)'">
            <span class="indice-color w-4 h-4 mr-1" :class="o3.threshold"></span>O₃
            <br>
            {{  o3.label }}
          </div>
        </div>
      </div>
    </template>
    <div v-else class="mx-auto">
      No data available
    </div>
    <ui-loader v-if="loading" />

  </div>
</template>

<script type="ts">
import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'

export default Vue.extend({
  components: {
    'ui-loader': Loader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    indice: {
      type: Object,
      required: false
    },
    o3: {
      type: Object,
      required: false
    },
    no2: {
      type: Object,
      required: false
    },
    pm10: {
      type: Object,
      required: false
    },
    pm25: {
      type: Object,
      required: false
    },
    valueDateDisplayed: {
      type: String,
      required: false,
      default: null
    },
    valueDate: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleDetails () {
      this.isOpen = !this.isOpen
    }
  }
})
</script>

<style scoped>
.indice-color {
  display: inline-block;
  box-shadow: 0 0 2px var(--color-font);
}

.indice-color.no_value {
  background: #EEEEEE;
}

.indice-color.very_low,
.indice-color.low {
  background: #50f0e6;
}

.indice-color.average {
  background: #50ccaa;
}

.indice-color.degrade {
  background: #F0E641;
}

.indice-color.high {
  background: #ff5050;
}

.indice-color.very_high {
  background: #960032;
}

.indice-color.extreme {
  background: #7d2181;
}

.airparif-legend {
  background: linear-gradient(90deg, #50f0e6 0%, #50ccaa 20%, #F0E641 40%, #ff5050 60%, #960032 80%, #7d2181 100%);
}
</style>
