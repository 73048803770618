<template>
  <div :class="'Loader ' + variant + ' ' + display">
    <div class='spinner spinner3' />
    <div class='message' v-if="message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'ui-loader',
  props: {
    message: String,
    variant: {
      type: String,
      default: 'font',
      validator (value) {
        // The value must match one of these strings
        return ['primary', 'font', 'dark'].indexOf(value) !== -1
      }
    },
    display: {
      type: String,
      default: 'normal',
      validator (value) {
        // The value must match one of these strings
        return ['normal', 'overlay', 'top'].indexOf(value) !== -1
      }
    }
  }
}
</script>

<style lang="postcss">

.Loader {
  color: var(--color-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 10px black;

  &.font {
    color: var(--color-font);
  }
  &.primary {
    color: var(--color-primary);
  }
  &.dark {
    color: var(--color-primary-dark);
  }

  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1500;
    background-color: var(--color-background);
  }
  &.normal {
    position: absolute;
    z-index: 3;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
  }
  &.top {
    position: absolute;
    z-index: 3;
    top: 1rem;
    width: 100%;
  }

}
.message {
  margin-top: 1em;
}

.spinner {
  width: 2em;
  height: 2em;
  margin: auto;
  &.spinner3{
    border-top: 3px solid ;
    border-right: 3px solid transparent;
    border-radius: 50%;
    animation: rotation .8s linear infinite;
  }
}

@keyframes rotation{
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

@keyframes flip{
  0%{
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50%{
    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
  }
  100%{
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
}

@keyframes progress{
  0%{
    left: -130%;
    opacity: 0.5;
  }
  50%{
    left: 130%;
    opacity: 0.5;
  }
  51%{
    background: rgba($color-white, .5);
  }
  100%{
    background: rgba($color-white, .5);
  }
}

@keyframes pulse{
  0%{
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  100%{
    transform: translate(-50%, -50%) scale(1.0);
    opacity: 0;
  }
}

@keyframes pulse2{
  0%, 100%{
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50%{
    transform: translate(-50%, -50%) scale(1.0);
    opacity: 0;
  }
}

@keyframes flipWalker{
  0%{
    transform: translate(0, 0) rotateX(0) rotateY(0);
  }
  25%{
    transform: translate(100%, 0) rotateX(0) rotateY(180deg);
  }
  50%{
    transform: translate(100%, 100%) rotateX(-180deg) rotateY(180deg);
  }
  75%{
    transform: translate(0, 100%) rotateX(-180deg) rotateY(360deg);
  }
  100%{
    transform:  translate(0, 0) rotateX(0) rotateY(360deg);
  }
}

</style>
