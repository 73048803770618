
















import Vue from 'vue'

export default Vue.extend({
  name: 'WetBulbGlobeTemperature',
  props: {
    lastWBGT: {
      type: Number
    },
    thresold1: {
      type: Number,
      default: 28
    },
    thresold2: {
      type: Number,
      default: 30
    },
    thresold3: {
      type: Number,
      default: 32
    }
  },
  computed: {
    cssProps () {
      return {
        width: '35px',
        '--rotation': `${this.lastWBGT * 180 / 50}deg`, // 50 === Max value (arbitrarily selected)
        '--color': this.colorGauge
      }
    },
    colorGauge () {
      if (!this.lastWBGT) return '#FFF'
      if (this.lastWBGT >= this.thresold3) {
        return 'var( --color-red)'
      } else if (this.lastWBGT >= this.thresold2) {
        return 'var( --color-orange)'
      } else if (this.lastWBGT >= this.thresold1) {
        return 'var( --color-yellow)'
      } else {
        return 'var( --color-green)'
      }
    }
  }
})

