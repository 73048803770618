import { DateTime } from 'luxon'
import apiService from '@/services/api'
import { filterForecastDataByDate } from './helpers'
import { vrsStoreConfig } from '@/store/config/store'
import { StateForecast } from './definitions'
import { WeatherEventSession } from '../event/definitions'
import { createStore } from 'vue-reactive-store'

const state: StateForecast = {
  loading: false,
  error: null,
  data: {
    daily: null,
    hourly: null,
    rain: null
  },
  apiData: null,
  lastReferenceDateUsed: null
}

const wsWorker = new Worker('@/worker/mfs.worker.ts', { type: 'module' })

const store = {
  name: 'forecast',
  state,
  actions: {
    /**
     * Fetch all forecast data (daily, hourly, rain)
     * for an event (id, timezone)
     * and a reference date.
     * The reference date is used to select the data to display.
     * @param {string|number} eventId
     */
    async fetchForecastData (eventId, eventTimezone: string) {
      state.loading = true
      try {
        const result = await Promise.all([
          apiService.getForecastDaily(eventId),
          apiService.getForecastHourly(eventId),
          apiService.getForecastRain(eventId)
        ])

        wsWorker.postMessage({
          type: 'forecast',
          data: {
            forecastData: result.map(r => r.data),
            eventTimezone
          }
        })
      } catch (e) {
        console.error(e)
        store.state.error = e
        store.state.loading = false
      }
    },

    /**
     * Filter data by date
     *
     * @param referenceDate DateTime object of forecast data to show
     * @param eventTimezone
     * @param sessions
     */
    computeForecastDataToDisplay (
      referenceDate: DateTime,
      eventTimezone: string,
      sessions: Array<WeatherEventSession>
    ) {
      // If no apiData, do nothing
      if (!state.apiData) return

      state.lastReferenceDateUsed = referenceDate

      state.data = {
        ...filterForecastDataByDate(state.apiData,
          referenceDate,
          eventTimezone,
          sessions,
          vrsStoreConfig.state.data
        )
      }
    },
    /**
     * Reset the state
     */
    resetState () {
      state.loading = false
      state.error = null
      state.data = {
        daily: null,
        hourly: null,
        rain: null
      }
      state.apiData = null
    }
  }
}

wsWorker.onmessage = function (e) {
  store.state.loading = false
  store.state.apiData = e.data
}

export const fetchForecastData = store.actions.fetchForecastData
export const computeForecastDataToDisplay = store.actions.computeForecastDataToDisplay

export const vrsStoreForecast = createStore(store)
