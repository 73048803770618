






































































import Vue from 'vue'
import { getPaths } from '@/components/data/common/helpers'

export default Vue.extend({
  name: 'temperature-line',
  props: {
    graphHeight: Number,
    columnWidth: Number,
    strokeWidth: Number,
    circleRadius: Number,
    offsetWidth: Number,
    offsetHeight: Number,
    textColor: String,
    mainColor: String,
    isOverLimit: {
      type: Number,
      default: null
    },
    circleOverLimitColor: String,
    pathColor: String,
    gradientColorStart: String,
    notes: {},
    width: String,
    maxWidth: String,
    bottom: String,
    isStrictLineBetweenCircle: {
      type: Boolean,
      default: false
    },
    min: Number,
    max: Number
  },
  data: () => ({
    paths: {
      main: null,
      gradient: null,
      circles: null
    }
  }),
  computed: {
    graphWidth () {
      return this.columnWidth * this.notes.length
    }
  },
  methods: {
    buildLine () {
      this.paths = {
        ...getPaths(
          this.graphHeight,
          this.graphWidth,
          this.notes,
          this.offsetHeight,
          this.offsetWidth,
          this.columnWidth,
          this.circleRadius,
          this.isStrictLineBetweenCircle,
          null,
          this.min,
          this.max
        )
      }
    }
  },
  watch: {
    graphWidth () {
      this.buildLine()
    },
    notes: {
      deep: true,
      immediate: true,
      handler () {
        this.buildLine()
      }
    }
  }
})

