<template>
  <div class='EventTime overflow-hidden'>
    <button
      aria-label='Show date selection'
      @click.stop="toggleDateSelector"
      :class="{'cursor-default': !displayEventDateSelector}"
      class="flex flex-col"
    >
      <div class="flex items-center">
        <span v-if="! active && displayEventDateSelector" class='icon-fa-stack' />
        <div class='EventTime__content'>
          <div class='flex items-baseline flex-wrap'>
            <span class='text-xl lg:text-3xl truncate'>
              {{label}}
            </span>
            <span class='ml-0 md:ml-2 lg:ml-4 lg:text-xl'>
              {{period}}
            </span>
          </div>
        </div>
      </div>
      <div
        class='EventTime__dates'
        :class='{"EventTime__dates--live": live}'
        v-if="displayEventDateSelector"
      >
        <span v-if="live">LIVE</span>
        <span v-else-if="isInFuture">Upcoming event</span>
        <span v-else>Arc. at {{dateToDisplay}}</span>
      </div>
    </button>
    <div v-if="showDateSelector && displayEventDateSelector">
      <button
        class='EventTime__overlay'
        arial-label='Hide date selection'
        @click.stop="toggleDateSelector"
      />
      <date-selector
        @close="toggleDateSelector"
        @toggle-live="$emit('toggle-live')"
        @change-ref-date="t => $emit('change-ref-date', t)"
        :active="active"
        :reference-date="referenceDate"
        :live="live"
        :date-range="dateRange"
      />
    </div>
  </div>
</template>

<script>
import DateSelector from '@/components/ui/DateSelector/DateSelector.vue'
import { convertDate } from '@/helpers/dates'

export default {
  name: 'event-time',
  components: {
    'date-selector': DateSelector
  },
  props: {
    active: Boolean,
    referenceDate: String,
    label: String,
    period: String,
    live: Boolean,
    dateRange: {
      default: () => ([])
    },
    displayEventDateSelector: {
      type: Boolean,
      default: false
    },
    isInFuture: Boolean
  },
  data () {
    return {
      showDateSelector: false
    }
  },
  computed: {
    dateToDisplay () {
      if (!this.referenceDate) return ''
      return convertDate(this.referenceDate).toFormat('yyyy-MM-dd T')
    }
  },
  methods: {
    toggleDateSelector () {
      if (!this.active && this.isInFuture) return
      this.showDateSelector = !this.showDateSelector
    }
  }
}

</script>

<style lang="postcss" scoped>
.EventTime {
  & button {
    width: 100%;
    flex-wrap: wrap;
    background: none;
    border: none;
    text-align: left;
    padding: 0;
    margin: 0;
  }

  .icon-fa-stack {
    margin-right: .5em;
    flex: 0 0 0;
    font-size: 1.25em;
  }

  &__content {
    flex: 1 1 0;
  }

  &__dates {
    font-size: 1em;
    color: $color-orange;

    &--live {
      color: $color-green;
    }

    &__icon[class^="icon-fa-"] {
      font-size: 14px;
      margin-left: 4px;
    }
  }

  & .EventTime__overlay {
    position: fixed;
    z-index: 5;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    border: none;
  }

  @media (min-width: 375px) {
    &__title {
      font-size: 1.2em;
    }
  }

  @media (min-width: $breakpoint-xs) {
    &__title {
      font-size: 1.2em;
    }
  }

  @media (min-width: 960px) {
    &__title {
      font-size: 1.3em;
    }
  }
}

</style>
