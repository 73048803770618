<template>
  <div class="Profile container">
    <h1>Profile</h1>

    <div class="errorMessage" v-if='auth.error && auth.error.message'>
      {{auth.error.message}}
    </div>

    <div v-for="fields in inputFields" :key="fields.prop">
      <label class="w-100">
        {{fields.label}}
        <br/>
        <input
          class="w-100"
          v-model="formData[fields.prop]"
          v-bind="fields.attrs"
        />
      </label>
    </div>

    <ui-button
      class="w-100"
      type='submit'
      v-if="!auth.loading"
      @click="updateUser"
    >
      <span>Update</span>
    </ui-button>

    <ui-loader
      class="w-100 block center"
      v-else
      message="waiting..."
      variant="font"
    />

    <div v-if="success" class="center">
      Your account has been updated !
    </div>

  </div>
</template>

<script>
import Button from '@/components/ui/Button/Button.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import { vrsStoreAuth } from '../../store/auth/store'

export default {
  name: 'Profile',
  components: {
    'ui-button': Button,
    'ui-loader': Loader
  },
  data () {
    return {
      formData: {
        first_name: '',
        last_name: '',
        username: '',
        email: ''
      },

      success: false,

      auth: vrsStoreAuth.state,

      inputFields: [{
        prop: 'first_name',
        label: 'First name',
        attrs: {}
      }, {
        prop: 'last_name',
        label: 'Last name',
        attrs: {}
      }, {
        prop: 'email',
        label: 'Email',
        attrs: {}
      }, {
        prop: 'username',
        label: 'Username (read only)',
        attrs: {
          disabled: true
        }
      }]
    }
  },
  async created () {
    await vrsStoreAuth.actions.getUser()
    for (const key in this.formData) {
      this.formData[key] = vrsStoreAuth.state.data.user[key]
    }
  },
  methods: {
    async updateUser () {
      await vrsStoreAuth.actions.update(this.formData)
      this.success = true
      setTimeout(() => { this.success = false }, 5000)
    }
  }
}
</script>

<style lang="postcss" scoped>
.Profile {
  width: 100%;
  margin: 2em auto;
  @media (min-width: $breakpoint-xs) {
    width: 40em;
  }
  h1 {
    text-align: center;
  }
  h2 {
    margin-top: 1em;
  }
  label {
    display: block;
    margin-bottom: $default-spacing;
  }
  input, .Button, select {
    padding: $default-spacing;
    margin-right: $default-spacing;
  }
  .errorMessage {
    background-color: $color-red;
    color: var(--color-primary-dark);
    padding: 1rem;
    font-size: 1.2rem;
    margin: 1em auto;
  }
  .Loader {
    width: 100%;
    position: unset;
  }

}

</style>
