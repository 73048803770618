import apiService from '@/services/api'
import {
  calcIntersectionBetweenRangeAndTimeRanges,
  getDefaultSelectedRange,
  getTimesToDisplay
} from './helpers'
import { convertDate, stringifyDate } from '@/helpers/dates'
import { StateTime, WeatherTime } from './definitions'
import { vrsStoreApp } from '../app/store'
import { vrsStoreEvent } from '../event/store'
import { createStore } from 'vue-reactive-store'
import { vrsStoreAuth } from '../auth/store'
import { DateTime } from 'luxon'
import { D3TimelineSerie } from '@/components/ui/Timeline/D3Timeline/timeline'

const state: StateTime = {
  bbox: null,
  loading: false,
  error: null,
  data: [],
  indexTimesToDisplayUTC: null,
  timeComputation: 'radar'
}

const store = {
  name: 'time',
  state,
  computed: {
    currentTime (): WeatherTime {
      if (!this.timesToDisplayUTC) return null
      const timesToDisplayUTC = this.timesToDisplayUTC
      if (
        timesToDisplayUTC.length === 0 ||
        state.indexTimesToDisplayUTC === null ||
        state.indexTimesToDisplayUTC === undefined ||
        state.indexTimesToDisplayUTC < 0 ||
        !timesToDisplayUTC[state.indexTimesToDisplayUTC]
      ) {
        return {
          utc: null,
          local: null,
          title: null,
          display: null,
          luxonDate: null
        }
      }
      const luxonDate = convertDate(timesToDisplayUTC[state.indexTimesToDisplayUTC], 'utc').setZone(vrsStoreApp.state.data.eventTimezone)
      return {
        utc: timesToDisplayUTC[state.indexTimesToDisplayUTC],
        local: stringifyDate(luxonDate),
        title: `${luxonDate.toFormat('D')} ${luxonDate.toFormat('T')}`,
        display: luxonDate.toFormat('T'),
        luxonDate
      }
    },
    /**
     * We build the local range only if
     * * the actual selectedRangeLocal is not set
     * * the actual selectedRangeLocal is not inside the available range
     */
    selectedRangeLocal (): [string, string] {
      if (
        /**
         * if no event loaded
         */
        !vrsStoreEvent.state.data ||
        /**
         * if no radar images AND we don't have access to airparif data
         */
        (
          vrsStoreEvent.state.data.radarActivityPeriods?.length === 0 &&
          !vrsStoreAuth.computed.displayAirParifData()
        )
      ) return [null, null]

      if (
        !vrsStoreEvent.state.data.dateRange?.[0] ||
        !vrsStoreEvent.state.data.dateRange?.[1]
      ) return [null, null]
      return getDefaultSelectedRange(
        [
          vrsStoreEvent.state.data.dateRange[0],
          vrsStoreEvent.state.data.dateRange[1]
        ],
        vrsStoreApp.state.data.referenceDate,
        vrsStoreApp.state.data.animationLength || vrsStoreApp.state.data.currentPageDefaultAnimationLength || vrsStoreApp.state.data.defaultAnimationLength,
        vrsStoreApp.state.data.live,
        vrsStoreApp.state.data.maximumTimeLive
      )
    },
    /**
     * All range of available times, in local timezone
     */
    availableTimeRangesLocal (): D3TimelineSerie {
      if (
        !vrsStoreEvent.state.data ||
        !vrsStoreEvent.state.data.radarActivityPeriods
      ) {
        return {
          data: [],
          type: 'radar',
          name: 'Radar images'
        }
      }
      const data = vrsStoreEvent.state.data.radarActivityPeriods.map(
        function (currentRange: [string, string]): [string, string] {
          return [
            stringifyDate(DateTime.fromISO(currentRange[0]).setZone(vrsStoreApp.state.data.eventTimezone)),
            stringifyDate(DateTime.fromISO(currentRange[1]).setZone(vrsStoreApp.state.data.eventTimezone))
          ]
        }
      )
      return {
        data,
        type: 'radar',
        name: 'Radar images'
      }
    },
    /**
     * UTC Times to be used by the map and the player
     */
    timesToDisplayUTC (): string[] {
      if (!this.selectedRangeLocal[0] || !this.selectedRangeLocal[1]) return []

      const intersectionRangeLocal = vrsStoreAuth.computed.displayAirParifData() && state.timeComputation === 'airquality'
        ? [...this.selectedRangeLocal]
        : calcIntersectionBetweenRangeAndTimeRanges(
          this.selectedRangeLocal,
          this.availableTimeRangesLocal
        )
      if (!intersectionRangeLocal) return []

      const selectedRangeUTC = [
        stringifyDate(convertDate(intersectionRangeLocal[0], vrsStoreApp.state.data.eventTimezone).toUTC()),
        stringifyDate(convertDate(intersectionRangeLocal[1], vrsStoreApp.state.data.eventTimezone).toUTC())
      ]

      return getTimesToDisplay(
        selectedRangeUTC[0],
        selectedRangeUTC[1]
      )
    },
    beginPeriodTimesToDisplayLocal () {
      if (this.timesToDisplayUTC.length === 0) return ''
      return stringifyDate(convertDate(this.timesToDisplayUTC[0], 'utc').setZone(vrsStoreApp.state.data.eventTimezone), 'HH:mm')
    },
    endPeriodTimesToDisplayLocal () {
      if (this.timesToDisplayUTC.length === 0) return ''
      return stringifyDate(convertDate(this.timesToDisplayUTC[this.timesToDisplayUTC.length - 1], 'utc').setZone(vrsStoreApp.state.data.eventTimezone), 'HH:mm')
    },
    lastDateUTCFromRange (): DateTime {
      if (!this.selectedRangeLocal[1]) return null
      return convertDate(this.selectedRangeLocal[1], vrsStoreApp.state.data.eventTimezone).toUTC()
    },
    /**
     * is the delta between the last radar image available in the range
     * more older than 15 minutes from the last date of the range
     */
    deltaBetweenLastDateFromRangeAndLastRadarImage () {
      if (this.timesToDisplayUTC.length === 0) return Infinity
      if (!this.lastDateUTCFromRange) return Infinity
      const lastRadarImage = convertDate(this.timesToDisplayUTC[this.timesToDisplayUTC.length - 1], 'utc')
      return (this.lastDateUTCFromRange.valueOf() - lastRadarImage.valueOf()) / 60 / 1000
    },
    isInRadarPeriod () {
      const { start, end } = this.currentRadarPeriod || {}
      if (!(start && end)) return false
      return (
        start &&
        end &&
        convertDate(start, vrsStoreApp.state.data.eventTimezone).toUTC() <= this.lastDateUTCFromRange &&
        this.lastDateUTCFromRange <= convertDate(end, vrsStoreApp.state.data.eventTimezone).toUTC())
    },
    displayWarningMessage (): boolean {
      if (!this.isInRadarPeriod) return false
      return this.deltaBetweenLastDateFromRangeAndLastRadarImage >= vrsStoreAuth.computed.displayNowcastingOptions().warningDelay
    },
    displayInfoMessage (): boolean {
      // if we are in a radar period, don't display info message
      if (this.isInRadarPeriod) return false
      // here, we aren't in the radar period
      return this.deltaBetweenLastDateFromRangeAndLastRadarImage >= 2
    },
    warningMessage (): string {
      if (this.timesToDisplayUTC.length > 0) {
        return 'Last valid image is ' + this.endPeriodTimesToDisplayLocal
      }
    },
    currentRadarPeriod () {
      if (!this.lastDateUTCFromRange) {
        return {}
      }
      const { radarPeriods } = vrsStoreEvent.state.data
      if (!radarPeriods || radarPeriods.length <= 0) {
        return {}
      }
      const radarPeriod = radarPeriods.find(({ start, end }) => {
        const startDay = convertDate(start).get('day')
        const endDay = convertDate(end).get('day')
        const currentDay = this.lastDateUTCFromRange.get('day')
        return (startDay <= currentDay && currentDay <= endDay)
      })
      return radarPeriod
    }
  },
  actions: {
    /**
     * Fetch the radar bbox for the eventId
     *
     * @param eventId Id of the event
     */
    async fetchRadarBbox (eventId: number) {
      state.loading = true
      try {
        const radarImageBbox = await apiService.getRadarBbox(eventId)
        state.bbox = radarImageBbox.data.bbox
      } catch (error) {
        console.error(error)
        state.error = error
      }
      state.loading = false
    },
    /**
     * Reset the state to its initial value
     */
    resetState () {
      state.data = []
      // state.availableTimestampsUTC = []
      state.indexTimesToDisplayUTC = null
    }
  },
  watch: {
    timesToDisplayUTC (newValue, oldValue) {
      if (!newValue || newValue.length === 0) return
      const isLastIndex = (state.indexTimesToDisplayUTC === oldValue.length - 1)
      if (
        /**
         * if we had the index at the end of the array,
         * we keep it like this to still display the last image
         */
        isLastIndex ||
        /**
         * If actual indexTimesToDisplayUTC doesn't exist
         * or is out of the array,
         * we reset it to the last one
         */
        state.indexTimesToDisplayUTC === null ||
        state.indexTimesToDisplayUTC > newValue.length
      ) {
        state.indexTimesToDisplayUTC = newValue.length - 1
      }
    }
  }
}

export const fetchRadarBbox = store.actions.fetchRadarBbox

export const vrsStoreTime = createStore(store)
