import { UserPermissions } from '@/store/auth/definitions'
import { LatLng } from 'leaflet'

/**
 * User preferences,
 * store token, zoom, last page, ...
 */
export interface UserPreference {
  token?: string;
  lastURL?: string;
  view?: string;
  frameRate?: number;
  stations?: object;
  zooms?: object;
  osmOpacity?: number;
  radarOpacity?: number;
  airParifOpacity?: number;
  dataLayer?: 'airquality' | 'radar';
  isLayerWindArrowsDisplayed: boolean;
  'layer-osm'?: boolean;
  'layer-weatherStations'?: boolean;
  'layer-rainpath'?: boolean;
  defaultAnimationLength: number;
  username: string;
  permissions: UserPermissions;
  events?: Record<number, {
    mapMainCenter?: { lat: number; lng: number };
    mapZoomCenter?: { lat: number; lng: number };
  }>|null;
}

const defaultPreferences = JSON.stringify({
  stations: {},
  view: 'radar',
  frameRate: 2,
  osmOpacity: 0.9,
  radarOpacity: 0.9,
  airParifOpacity: 0.9,
  dataLayer: 'radar',
  isLayerWindArrowsDisplayed: false,
  'layer-osm': true,
  'layer-weatherStations': false,
  'layer-rainpath': false,
  defaultAnimationLength: 30,
  username: 'Anonymous',
  permissions: {},
  events: {}
})

class PreferenceService implements UserPreference {
  private _userPreferences: UserPreference

  constructor () {
    let localStoragePreferences: UserPreference
    try {
      localStoragePreferences = JSON.parse(localStorage.getItem(STORAGE_KEY_SETTINGS))
    } catch (e) {
      console.error('Settings stored in localStorage are not well formed')
    }
    this._userPreferences = {
      ...JSON.parse(defaultPreferences),
      ...localStoragePreferences
    }
  }

  private updatePreferences (newPreferences: UserPreference) {
    try {
      const userPreferencesJSON = JSON.stringify(newPreferences)
      localStorage.setItem(STORAGE_KEY_SETTINGS, userPreferencesJSON)
      this._userPreferences = newPreferences
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  updateStations (key: string, value: boolean) {
    this.updatePreferences({
      ...this._userPreferences,
      stations: {
        ...this._userPreferences.stations,
        [key]: value
      }
    })
  }

  updateZooms (key: string, value: Record<string, any>) {
    this.updatePreferences({
      ...this._userPreferences,
      zooms: {
        ...this._userPreferences.zooms,
        [key]: value
      }
    })
  }

  /**
   * Store the center of the map by event
   */
  updateEventMapCenter (eventId: number, map: 'zoom' | 'main', center?: LatLng) {
    this.updatePreferences({
      ...this._userPreferences,
      events: {
        ...this._userPreferences.events,
        [eventId]: {
          ...(this._userPreferences.events[eventId] || {}),
          [map === 'main' ? 'mapMainCenter' : 'mapZoomCenter']: center
            ? {
              lat: center.lat,
              lng: center.lng
            }
            : null
        }
      }
    })
  }

  get events () {
    return this._userPreferences.events || {}
  }

  get stations () {
    return this._userPreferences.stations || {}
  }

  get zooms () {
    return this._userPreferences.zooms || {}
  }

  get token () {
    return this._userPreferences.token
  }

  set token (newValue) {
    this.updatePreferences({
      ...this._userPreferences,
      token: newValue || ''
    })
  }

  get lastURL () {
    return this._userPreferences.lastURL
  }

  set lastURL (newValue) {
    this.updatePreferences({
      ...this._userPreferences,
      lastURL: newValue
    })
  }

  get frameRate () {
    return this._userPreferences.frameRate
  }

  set frameRate (newValue: number) {
    this.updatePreferences({
      ...this._userPreferences,
      frameRate: newValue
    })
  }

  get view () {
    return this._userPreferences.view
  }

  set view (newValue) {
    this.updatePreferences({
      ...this._userPreferences,
      view: newValue
    })
  }

  get defaultAnimationLength () {
    return this._userPreferences.defaultAnimationLength
  }

  set defaultAnimationLength (newValue: number) {
    this.updatePreferences({
      ...this._userPreferences,
      defaultAnimationLength: newValue
    })
  }

  get 'layer-osm' () {
    return this._userPreferences['layer-osm']
  }

  set 'layer-osm' (newValue: boolean) {
    this.updatePreferences({
      ...this._userPreferences,
      'layer-osm': newValue
    })
  }

  get 'layer-rainpath' () {
    return this._userPreferences['layer-rainpath']
  }

  set 'layer-rainpath' (newValue: boolean) {
    this.updatePreferences({
      ...this._userPreferences,
      'layer-rainpath': newValue
    })
  }

  get 'layer-weatherStations' () {
    return this._userPreferences['layer-weatherStations']
  }

  set 'layer-weatherStations' (newValue: boolean) {
    this.updatePreferences({
      ...this._userPreferences,
      'layer-weatherStations': newValue
    })
  }

  get osmOpacity (): number {
    return this._userPreferences.osmOpacity
  }

  set osmOpacity (newValue: number) {
    this.updatePreferences({ ...this._userPreferences, osmOpacity: newValue })
  }

  get radarOpacity (): number {
    return this._userPreferences.radarOpacity
  }

  set radarOpacity (newValue: number) {
    this.updatePreferences({ ...this._userPreferences, radarOpacity: newValue })
  }

  get airParifOpacity (): number {
    return this._userPreferences.airParifOpacity
  }

  set airParifOpacity (newValue: number) {
    this.updatePreferences({ ...this._userPreferences, airParifOpacity: newValue })
  }

  get dataLayer (): 'airquality' | 'radar' {
    return this._userPreferences.dataLayer
  }

  set dataLayer (newValue: 'airquality' | 'radar') {
    this.updatePreferences({ ...this._userPreferences, dataLayer: newValue })
  }

  get username () {
    return this._userPreferences.username
  }

  set username (newValue: string) {
    this.updatePreferences({ ...this._userPreferences, username: newValue })
  }

  get isLayerWindArrowsDisplayed () {
    return this._userPreferences.isLayerWindArrowsDisplayed
  }

  set isLayerWindArrowsDisplayed (newValue: boolean) {
    this.updatePreferences({ ...this._userPreferences, isLayerWindArrowsDisplayed: newValue })
  }

  get permissions () {
    return this._userPreferences.permissions
  }

  set permissions (newValue: UserPermissions) {
    this.updatePreferences({ ...this._userPreferences, permissions: newValue })
  }
}

export default new PreferenceService()
