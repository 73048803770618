

























































































import Vue from 'vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import UpdateTime from '@/components/ui/UpdateTime/UpdateTime.vue'

export default Vue.extend({
  props: {
    day: String,
    time: String,
    type: Number,
    comment: String,
    beginning: [String, Number],
    end: [String, Number],
    intensity: String,
    interm: String,
    direction: Number,
    cardinalDirection: String,
    speed: Number,
    loading: Boolean,
    defaultAlertInfo: String,
    isMessage: Boolean,
    message: String,
    updateTime: String,
    status: Number,
    isInHeader: Boolean,
    displayRow: {
      type: Boolean,
      default: false
    },
    displayStatus: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Rain forecast'
    },
    displayTitle: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'ui-loader': Loader,
    'ui-update-time': UpdateTime
  },
  computed: {
    statusSuffix () {
      switch (this.status) {
        case 0:
          return 'green'
        case 1:
          return 'orange'
        case 2:
          return 'red'
        default:
          return 'yellow'
      }
    }
  }
})

