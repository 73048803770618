import { stringifyDate } from '@/helpers/dates'
import { DateTime } from 'luxon'
import { WeatherEvent, WeatherEventDate, WeatherEventProperties, WeatherEventStation, WeatherSeason } from './definitions'

/**
 * Returns period event string
 * @param startDate Start Date in format 'yyyy-mm-dd'
 * @param endDate End date in format 'yyyy-mm-dd'
 */
export function getSingleEventDates (startDate: string, endDate: string): WeatherEventDate {
  const [yearStart, monthStart, dayStart] = startDate.split('-')
  const [yearEnd, monthEnd, dayEnd] = endDate.split('-')
  const monthStartName = DateTime.local(+yearStart, +monthStart).toFormat('MMM').toUpperCase()
  const monthEndName = DateTime.local(+yearEnd, +monthEnd).toFormat('MMM').toUpperCase()

  return {
    displayDate: dayStart,
    displayMonth: monthStartName,
    displayEndDate: dayEnd,
    displayEndMonth: monthEndName,
    startDate: `${dayStart} ${monthStartName} ${yearStart}`,
    endDate: `${dayEnd} ${monthEndName} ${yearEnd}`,
    start_date: startDate,
    end_date: endDate,
    isInFuture: startDate.replace(/-/gi, '') >= DateTime.local().toFormat('yyyyMMdd'),
    period: (monthStartName === monthEndName
      ? (dayStart + ' > ' + dayEnd + ' ' + monthEndName)
      : (dayStart + ' ' + monthStartName + ' > ' + dayEnd + ' ' + monthEndName)
    )
  }
}

/**
 * Returns event properties ready to display
 *
 * @param event
 * @param id Id of the event
 */
export function getSingleEventProperties (event: any, id: number): WeatherEventProperties {
  return {
    id,
    active: event.active,
    code: event.code,
    countryIsoCode: event.country_iso_code,
    eventMapCx: event.event_map_cx,
    eventMapCy: event.event_map_cy,
    totalForecast: event.total_forecast_data,
    label: event.label,
    timezone: event.timezone,
    location: event.location,
    sessions: event.sessions,
    countryUnicode: event.country_iso_code?.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397)),
    eventMapCX: event.event_map_cx || 0,
    eventMapCY: event.event_map_cy || 0,
    radarPeriods: event.radar_periods,
    radarActivityPeriods: event.radar_activity_periods,
    stationsActivityPeriods: event.stations_activity_periods,
    year: event.year,
    compareDate: +event.start_date.replace(/-/g, ''),
    ...getSingleEventDates(event.start_date, event.end_date)
  }
}

/**
 * Calc the relative position of a station,
 * compared to other stations
 */
export function getStationsWithPosition (stationsToCompare: WeatherEventStation[]): WeatherEventStation[] {
  if (stationsToCompare.length > 4) throw new Error('Too much stations. Need at most 4 stations to process positions')
  // we want to retrieve the upper one, the lower one
  // and the more to the left, and the more to the right
  const position = {
    x: {
      min: null,
      max: null,
      left: null,
      right: null
    },
    y: {
      min: null,
      max: null,
      bottom: null,
      top: null
    }
  }
  stationsToCompare.forEach((s, index) => {
    if (!position.x.min || s.geometry.coordinates[0] < position.x.min) {
      position.x.min = s.geometry.coordinates[0]
      position.x.left = index
    }
    if (!position.x.max || s.geometry.coordinates[0] > position.x.max) {
      position.x.max = s.geometry.coordinates[0]
      position.x.right = index
    }
    if (!position.y.min || s.geometry.coordinates[1] < position.y.min) {
      position.y.min = s.geometry.coordinates[1]
      position.y.bottom = index
    }
    if (!position.y.max || s.geometry.coordinates[1] > position.y.max) {
      position.y.max = s.geometry.coordinates[1]
      position.y.top = index
    }
  })

  function getPositionForIndex (index: number) {
    let result = ''
    result += position.x.left === index ? 'left ' : ''
    result += position.x.right === index ? 'right ' : ''
    result += position.y.bottom === index ? 'bottom ' : ''
    result += position.y.top === index ? 'top ' : ''
    return result.trim()
  }

  return stationsToCompare.map((s, index) => ({
    ...s,
    properties: {
      ...s.properties,
      position: getPositionForIndex(index)
    }
  }))
}

/**
 * Parse data received from a single event
 *
 * @param event Event retrieved with API
 */
export function extractDataFromSingleEvent (event: any): WeatherEvent {
  const result: any = {}
  result.dateRange = [
    event.properties.start_date.replace(/-/g, '') + '000000',
    event.properties.end_date.replace(/-/g, '') + '235959'
  ]
  result.geojson = event.geometry
  result.summary = event.properties.overview_summary?.replaceAll('\n', '<br/>') || 'No summary for this event'
  result.default_alert_info = event.properties.default_alert_info
  result.default_forecast_info = event.properties.default_forecast_info
  result.stations = getStationsWithPosition(
    event.properties.stations.features
      .sort((a, b) => (a.properties.reference - b.properties.reference))
  )
  result.stationsActivityPeriods = event.properties?.stations_activity_periods?.map(
    function (currentRange: [string, string]): [DateTime, DateTime] {
      return [
        DateTime.fromISO(currentRange[0], {
          zone: event.properties.timezone,
          setZone: true
        }),
        DateTime.fromISO(currentRange[1], {
          zone: event.properties.timezone,
          setZone: true
        })
      ]
    }).reduce((accumulator: [DateTime, DateTime][], currentValue: [DateTime, DateTime]) => {
      if (accumulator.length === 0) return [currentValue]
      const lastAccumulatorItem = accumulator[accumulator.length - 1]
      /**
     * if the last item have a last range date <= 3mn of the currentValue first range date,
     * we merge the two range
     */
      if (lastAccumulatorItem[1].diff(currentValue[0]).as('minutes') <= 3) {
        lastAccumulatorItem[1] = currentValue[1]
      } else {
        accumulator.push(currentValue)
      }
      return accumulator
    }, []).map(function (currentRange: [DateTime, DateTime]): [string, string] {
      return [
        stringifyDate(currentRange[0]),
        stringifyDate(currentRange[1])
      ]
    })

  return {
    ...getSingleEventProperties(event.properties, event.id),
    ...result
  }
}

/**
 * Returns seasons, events array an activeEvent from a list of events
 *
 * @param result result from /events request
 */
export function extractDataFromEvents (result: any): {
  seasons: WeatherSeason[];
  events: WeatherEvent[];
  activeEvent: WeatherEvent;
} {
  let events: WeatherEvent[] = []
  const indexOfSeasons = {}
  let seasons: WeatherSeason[] = []
  let activeEvent: WeatherEvent = null
  // each event code will contains a { year, id } array
  const eventSeasonsByCode: Record<string, {
    year: number;
    id: number;
    label: string;
    period: string;
    compareDate: number;
  }[]> = {}

  /**
   * Build the seasons array,
   * by consolidating each event
   */
  for (let i = 0; i < result.length; i++) {
    const event: WeatherEvent = getSingleEventProperties(result[i], result[i].id)

    events.push(event)
    if (seasons.findIndex(v => v.year === event.year) < 0) {
      seasons.push({ year: event.year, events: [] })
      indexOfSeasons[event.year] = seasons.length - 1
    }
    seasons[indexOfSeasons[event.year]].events.push(event)

    if (!eventSeasonsByCode[event.code]) {
      eventSeasonsByCode[event.code] = []
    }
    eventSeasonsByCode[event.code].push({
      year: event.year,
      id: event.id,
      label: event.label,
      period: event.period,
      compareDate: event.compareDate
    })

    if (event.active === true) {
      activeEvent = event
    }
  }

  /**
   * Sort events inside seasons by event compare date
   */
  for (let i = 0; i < seasons.length; i++) {
    seasons[i].events = seasons[i].events.sort((a, b) => {
      if (a.compareDate < b.compareDate) return -1
      return a.compareDate - b.compareDate
    })
    /**
     * Add seasons for each event in the current season
     *
     * We do this only once, and not for the events array.
     * This is due to the fact that events inside events array
     * and seasons[i].events are the same objects in memory.
     */
    seasons[i].events.forEach(currentEvent => {
      currentEvent.seasons = eventSeasonsByCode[currentEvent.code]
        .filter(e => e.id !== currentEvent.id)
        .sort((a, b) => {
          if (a.compareDate > b.compareDate) return -1
          return b.compareDate - a.compareDate
        })
    })
  }

  /**
   * Sort events by event compare date
   */
  events = events.sort((a, b) => {
    if (a.compareDate < b.compareDate) return -1
    return a.compareDate - b.compareDate
  })

  /**
   * Sort seasons by year
   */
  seasons = seasons.sort((a, b) => {
    if (a.year < b.year) return -1
    return a.year - b.year
  })

  /**
   * Add seasons for each event
   */

  return {
    seasons,
    events,
    activeEvent
  }
}

/**
 * All statistics data
 */
export const statistics = {
  minAirTemp: {
    icon: 'icon-ui-temperature',
    label: 'T° Air min',
    unity: '°C'
  },
  maxAirTemp: {
    icon: 'icon-ui-temperature',
    label: 'T° Air max',
    unity: '°C'
  },
  avgAirTemp: {
    icon: 'icon-ui-temperature',
    label: 'T° Air avg',
    unity: '°C'
  },
  minTrackTemp: {
    icon: 'icon-ui-track',
    label: 'T° Track min',
    unity: '°C'
  },
  maxTrackTemp: {
    icon: 'icon-ui-track',
    label: 'T° Track max',
    unity: '°C'
  },
  avgTrackTemp: {
    icon: 'icon-ui-track',
    label: 'T° Track avg',
    unity: '°C'
  },
  minHumidity: {
    icon: null,
    label: 'Hu min',
    unity: '%'
  },
  maxHumidity: {
    icon: null,
    label: 'Hu max',
    unity: '%'
  },
  avgHumidity: {
    icon: null,
    label: 'Hu avg',
    unity: '%'
  },
  minPressure: {
    icon: null,
    label: 'P min',
    unity: 'hPa'
  },
  maxPressure: {
    icon: null,
    label: 'P max',
    unity: 'hPa'
  },
  avgPressure: {
    icon: null,
    label: 'P avg',
    unity: 'hPa'
  },
  minWindSpeed: {
    icon: 'icon-fa-wind-strength',
    label: 'Wind speed min',
    unity: 'kph'
  },
  maxWindSpeed: {
    icon: 'icon-fa-wind-strength',
    label: 'Wind speed max',
    unity: 'kph'
  },
  avgWindSpeed: {
    icon: 'icon-fa-wind-strength',
    label: 'Wind speed avg',
    unity: 'kph'
  },
  minWindGust: {
    icon: 'icon-wind-vrb',
    label: 'Gust min',
    unity: 'kph'
  },
  maxWindGust: {
    icon: 'icon-wind-vrb',
    label: 'Gust max',
    unity: 'kph'
  },
  avgWindGust: {
    icon: 'icon-wind-vrb',
    label: 'Gust avg',
    unity: 'kph'
  },
  sumRainIntensity: {
    icon: 'icon-ui-rain',
    label: 'Rain total',
    unity: 'mm'
  },
  minRainIntensity: {
    icon: 'icon-ui-rain',
    label: 'Intensity min',
    unity: 'mm/h'
  },
  maxRainIntensity: {
    icon: 'icon-ui-rain',
    label: 'Intensity max',
    unity: 'mm/h'
  },
  mainWindDirection: {
    icon: 'icon-fa-wind-dir',
    label: 'Wind main dir',
    unity: '%'
  }
}

export function forecastDataExist (eventId: number, events: WeatherEventProperties[]): boolean {
  const currentEvent = events.find(({ id }) => id === eventId)
  return currentEvent.totalForecast > 0
}
