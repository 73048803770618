import { DateTime } from 'luxon'

/**
 * Returns a DateTime from a string with format 'yyyyMMddHHmmss'
 * The DateTime use the timezone if specified.
 *
 * @param datestring Datestring with following format: 'yyyyMMddHHmmss'.
 * @param timezone Standard timezone (e.g.: 'utc', 'America/New_York', ...).
 *   Defaults to local system's one.
 * @returns {DateTime}: DateTime corresonding to given input parameters.
 */
export function convertDate (datestring: string, timezone: string = null) {
  if (!datestring) return null
  const year = parseInt(datestring.substring(0, 4), 10)
  const month = parseInt(datestring.substring(4, 6), 10)
  const day = parseInt(datestring.substring(6, 8), 10)
  const hour = parseInt(datestring.substring(8, 10), 10)
  const minute = parseInt(datestring.substring(10, 12), 10)
  const second = parseInt(datestring.substring(12, 14), 10)

  const dateTimeParams = {
    year,
    month,
    day,
    hour,
    minute,
    second,
    zone: timezone
  }
  return DateTime.fromObject(dateTimeParams)
}

/**
 * Return time in HH:mm format
 *
 * @export
 * @param {string} datestring
 * @returns
 */
export function getTimeFromString (datestring: string) {
  if (!datestring) {
    return ''
  }
  const hour = datestring.substring(8, 10)
  const minute = datestring.substring(10, 12)
  return `${hour}:${minute}`
}

/**
 * Returns a date in string format 'yyyyMMddHHmmss' from a DateTime object
 *
 * @param date
 * @param format
 */
export function stringifyDate (date: DateTime, format = 'yyyyMMddHHmm00'): string {
  return date && date.toFormat(format)
}

/**
 * Converts a UNIX timestamp (in seconds) to a datestring
 *
 * @export
 * @param {number} timestamp: Unix timestamp (in seconds)
 * @returns {string} datestring
 */
export function getDateStringFromSeconds (timestamp: number) {
  const datetime = DateTime.fromSeconds(timestamp)
  return stringifyDate(datetime)
}

/**
 * Returns a Date from a string with format 'yyyyMMddHHmmss'
 * Note: Does not handle timezone.
 *
 * @param {string} datestring: Formatted as 'yyyyMMddHHmmss'.
 * @returns {Date}: Corresponding Date object, initialized as is (no timezone).
 */
export function convertDateStringToNoTimezoneDate (datestring: string) {
  const year = parseInt(datestring.substring(0, 4), 10)
  const month = parseInt(datestring.substring(4, 6), 10)
  const day = parseInt(datestring.substring(6, 8), 10)
  const hour = parseInt(datestring.substring(8, 10), 10)
  const minute = parseInt(datestring.substring(10, 12), 10)
  const second = parseInt(datestring.substring(12, 14), 10)

  return new Date(year, month - 1, day, hour, minute, second)
}

/**
 * Returns true if time is between range dates
 *
 * @param {string} time - a datestring (yyyyMMddHHmmss)
 * @param {array} range - an array of datestring
 */
export function isTimeInRange (time: string, range: Array<string>): boolean {
  return +time >= +range[0] && +time <= +range[1]
}

export function getTimeIndex (time: string, times: Array<string>): number {
  return times.findIndex(i => i === time)
}

/**
 * Returns times array filter by range
 *
 * @param {array} times
 * @param {array} range
 * @returns
 */
export function getSelectedTimes (times: Array<string>, range: Array<string>): Array<string> {
  return times.filter(time => isTimeInRange(time, range))
}
/**
 * Returns a datestring with 00 as seconds.
 *
 * @export
 * @param {string} datestring: Input time, formatted as 'yyyyMMddHHmmss'.
 * @returns
 */
export function resetDStringSeconds (datestring: string): string {
  return datestring.substring(0, 12) + '00'
}

/**
 * Returns current DateTime in local timezone.
 */
export function getNowDateTime (): DateTime {
  return DateTime.local()
}
